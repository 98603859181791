import React, { useRef } from "react";
import "./servicios.css";
import { FaAngleDown } from "react-icons/fa6";
import ServiciosVideo from "../../videos/ServiciosVideo.mp4";
import TarjetaImg1 from "../../img/serviciosCard1.jpg";
import TarjetaImg2 from "../../img/TarjetasServicios/E-COMMERS-IMG.png";
import TarjetaImg3 from "../../img/TarjetasServicios/BRAN-STRATEGY-IMG.png";
import TarjetaImg4 from "../../img/TarjetasServicios/DISEÑO-MAGICO-IMG.png";
import TarjetaImg5 from "../../img/TarjetasServicios/MANTENIMIENTO-IMG.png";
import TarjetaImg6 from "../../img/TarjetasServicios/EXPERIENCIA-DE-USUARIO-IMG.png";
import {ReactComponent as SVGTarjeta1} from "../../svg/TarjetasServicios/Mundo.svg";
import {ReactComponent as SVGTarjeta2} from "../../svg/TarjetasServicios/Mundo2.svg";
import {ReactComponent as SVGTarjeta3} from "../../svg/TarjetasServicios/Ventana.svg";
import {ReactComponent as SVGTarjeta4} from "../../svg/TarjetasServicios/tarjetaSVG4.svg";
import {ReactComponent as SVGTarjeta5} from "../../svg/TarjetasServicios/_1.svg";
import {ReactComponent as SVGTarjeta6} from "../../svg/TarjetasServicios/tarjetaSVG6.svg";
import { CardWIthImageAnimation } from "../../componentes/CardWithImageAnimation/CardWIthImageAnimation";
import { Button } from "../../componentes/Button/Button";
import Ventana from "../../svg/TarjetasServicios/Ventana.svg";
import Mundo1 from "../../svg/TarjetasServicios/Mundo.svg";
import Mundo2 from "../../svg/TarjetasServicios/Mundo2.svg";
import Tarjeta from "../../svg/TarjetasServicios/tarjetaSVG4.svg";
import Tarjeta2 from "../../svg/TarjetasServicios/tarjetaSVG6.svg";
import Tarjeta3 from "../../svg/TarjetasServicios/_1.svg";
import Modo from "../../svg/TarjetasServicios/Modo_de_aislamiento.svg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";


export default function Servicios() {
  const roundRef = useRef(null);
  const timelineRef = useRef(false);

  gsap.registerPlugin(useGSAP);
  const tarjetasData = [
    {
      titulo: "DESARROLLO WEB",
      texto: `Creamos sitios web a medida que se adaptan a tus necesidades
      específicas. Desde páginas informativas hasta portales complejos,
      nuestro equipo de desarrollo web se encarga de ofrecerte soluciones
      tecnológicas sólidas y fiables.`,
      imagen: TarjetaImg1,
      doodle: () => <SVGTarjeta1 className="doodle" />,
    },
    {
      titulo: "E-COMMERCE",
      texto: `Diseñamos y desarrollamos plataformas de comercio electrónico que
      maximizan tus ventas en línea. Desde la configuración inicial hasta
      la optimización continua, te ayudamos a crear una experiencia de
      compra en línea excepcional para tus clientes.`,
      imagen: TarjetaImg2,
      doodle: () => <SVGTarjeta2 className="doodle" />,
    },
    {
      titulo: "BRAND STRATEGY",
      texto: `Desarrollamos estrategias de marca sólidas que teayudan a
      diferenciarte en un mercado competitivo. Desde la investigación de
      mercado hasta la creación de posicionamiento, te ayudamos a
      construir una marcafuerte y coherente que resuene con tu audiencia.`,
      imagen: TarjetaImg3,
      doodle: () => <SVGTarjeta3 className="doodle" />,
    },
    {
      titulo: "DISEÑO GRAFICO",
      texto: `Nuestro equipo de diseñadores gráficos talentosos se encarga de dar
      vida a tus ideas a través de diseños creativos y atractivos. Desde
      el diseño de logotipos hasta la creación de materiales de marketing,
      nos aseguramos de que tu marca destaque en todos los aspectos
      visuales.`,
      imagen: TarjetaImg4,
      doodle: () => <SVGTarjeta4 className="doodle" />,
    },
    {
      titulo: "MANTENIMIENTO",
      texto: `Ofrecemos servicios de mantenimiento web continuo para garantizar
      que tu sitio web funcione sin problemas en todo momento. Desde
      actualizaciones de contenido hasta seguridad y rendimiento, nos
      encargamos de que tu sitio web esté siempre en óptimas condiciones...`,
      imagen: TarjetaImg5,
      doodle: () => <SVGTarjeta5 className="doodle" />,
    },
    {
      titulo: "EXPERIENCIA DE USUARIO",
      texto: `Nuestro equipo de expertos está aquí para brindarte orientación y
      asesoramiento personalizado en todas las etapas de tu proyecto
      digital. Ya sea que necesites ayuda con la planificación estratégica
      o la resolución de problemas técnicos, estamos aquí para ayudarte a
      alcanzar tus metas.`,
      imagen: TarjetaImg6,
      doodle: () => <SVGTarjeta6 className="doodle" />,
    },
  ];

  useGSAP(() => {
    timelineRef.current = gsap.timeline({ paused: true }).to(roundRef.current, {
      rotation: 180,
    });
  });

  const hoverOn = () => {
    timelineRef.current.timeScale(1).play();
  };

  const hoverOff = () => {
    timelineRef.current.timeScale(1).reverse();
  };

  return (
    <div className="main-servicios">
      <div className="background-servicios">
        <video
          autoplay="autoplay"
          loop="loop"
          id="video_background"
          preload="auto"
          volume="0"
          className="servicios-video"
        >
          <source src={ServiciosVideo} type="video/mp4" />
        </video>
        <div className="servicios-background-gradient" />
        <div className="servicios-contenedor">
          <h1>SERVICIOS</h1>
          <p>
            En Node Digital Studio, nos especializamos en ofrecerte soluciones
            digitales centradas en el desarrollo web. Nuestro enfoque principal
            es proporcionarte una plataforma sólida y atractiva en línea. A
            continuación, detallamos nuestros servicios:
          </p>
        </div>
      </div>
      <div className="servicios-explicacion-contenedor">
        {tarjetasData.map((tarjeta) => (
          <CardWIthImageAnimation {...tarjeta} />
        ))}
        <div className="consultoria-contenedor">
          <h1>CONSULTORÍA Y ASESORAMIENTO</h1>
          <p>
            Nuestro equipo de expertos está aquí para brindarte orientación y
            asesoramiento personalizado en todas las etapas de tu proyecto
            digital. Ya sea que necesites ayuda con la planificación estratégica
            o la resolución de problemas técnicos, estamos aquí para ayudarte a
            alcanzar tus metas.
          </p>
          <div className="consultoria-button ">
            <Button
              bgColor={"#0b0b0b"}
              bgHoverColor={"white"}
              containerClassname="proyectosButton"
            >
              {" "}
              <div className="boton">CONTACTA CON NOSOTROS</div>
            </Button>
          </div>
        </div>
      </div>
      <div className="final-contenedor">
        <div className="final-text">
          <div className="final-svg-1">
            <img src={Mundo1} alt="" />
            <img src={Mundo2} alt="" />
            <img src={Ventana} alt="" />
          </div>

          <p>
            TODO NUESTRO EQUIPO ESTÁ CALIFICADO PARA <br /> CADA APARTADO,
            TENEMOS UN GRAN <br />
            GRUPO HUMANO QUE TRABAJARÁ EN <br />
            CONJUNTO PARA CUMPLIR TODAS LAS EXPECTATIVAS.
          </p>
          <div className="final-svg-2">
            <img src={Tarjeta} alt="" />
            <img src={Tarjeta3} alt="" />
            <img src={Tarjeta2} alt="" />
          </div>
        </div>
      </div>
      <div className="final-svg-3">
        <img
          src={Modo}
          alt=""
          ref={roundRef}
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
        />
      </div>
    </div>
  );
}
