import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import "./index.css";
import { useGSAP } from "@gsap/react";
import { isMobile } from "react-device-detect";

gsap.registerPlugin(useGSAP);

export const Button = ({
  bgColor = "black",
  bgHoverColor = "white",
  borderRadius = "10px",
  containerClassname = "",
  children,
}) => {
  const buttonRef = useRef();
  const circleRef = useRef();
  const [measurements, setMeasurements] = useState();

  const [aux, setAux] = useState();

  const buttonAnimationRef = useRef();
  const removeMesureUnits = (value) => {
    console.log("button", buttonRef.current);
    const width = buttonRef.current.clientWidth;
    const height = buttonRef.current.clientHeight;
    /*if (aux.includes("px"))
      return { measure: aux.replace("px", ""), unit: "px" };
    if (aux.includes("vh"))
      return { measure: aux.replace("vh", ""), unit: "vh" };
    if (aux.includes("vw"))
      return { measure: aux.replace("vw", ""), unit: "vw" };
    if (aux.includes("rem"))
      return { measure: aux.replace("rem", ""), unit: "rem" };*/
    console.log(width, height);
    return {
      width: { measure: width, unit: "px" },
      height: { measure: height, unit: "px" },
    };
  };
  useGSAP(() => {
    gsap.set(circleRef.current, { translate: "-50% -50%" });
  }, []);
  const onMouseEnter = (e) => {
    let rect = buttonRef.current.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    let y = e.clientY - rect.top; //y position within the element.
    console.log("e: ", e, "rect", rect);
    gsap.set(circleRef.current, { top: y, left: x });
    buttonAnimationRef.current = gsap.timeline({}).fromTo(
      circleRef.current,
      { scale: 0 },
      {
        scale: 1,
      }
    );
  };

  const onMouseLeave = () => {
    buttonAnimationRef.current.reverse();
  };

  useEffect(() => {
    setMeasurements(removeMesureUnits());
    console.log("updatedMeasurements", measurements);
  }, []);

  return (
    <div
      className={containerClassname + " buttonContainer"}
      style={{
        backgroundColor: bgColor,
        borderRadius: borderRadius,
      }}
    >
      <div
        className="button"
        ref={buttonRef}
        onMouseEnter={!isMobile ? (e) => onMouseEnter(e) : null}
        onMouseLeave={!isMobile ? () => onMouseLeave() : null}
      >
        {children}
      </div>
      <div
        className="circle"
        ref={circleRef}
        style={
          measurements && {
            width: measurements?.width.measure * 2.2 + measurements?.width.unit,
            height:
              measurements?.width.measure * 2.2 + measurements?.width.unit,
            backgroundColor: bgHoverColor,
          }
        }
      />
    </div>
  );
};
