import React from "react";
import "./dachbone.css";
import Rayita from "../../svg/rayitas-chotas.svg";
import Rayitanaranja from "../../svg/rayitas-chotas-naranjas.svg";
import { FaAngleDown } from "react-icons/fa6";
import DachboneGalleryImage1 from "../../img/dachboneGalleryImage1.png";
import DachboneGalleryImage2 from "../../img/dachboneGalleryImage2.png";
import DachboneGalleryImage3 from "../../img/dachboneGalleryImage3.png";
import DachboneGalleryImage4 from "../../img/dachboneGalleryImage4.png";
import DogFriendlyDoodle from "../../svg/dogFriendlyDoodle.svg";
import PerritoSection3 from "../../img/perritoSection3.jpg";
import { ReactComponent as PerritoBlancoDoodle } from "../../svg/perritoBlanco.svg";
import DachboneVideo from "../../videos/perritoVideo.mp4";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";

export default function Dachbone() {
  return (
    <div className="dachbone-main">
      <div className="dachbone-contenedor">
        <div className="dachbone-titulo">
          <h5>DACHBONE</h5>
          <div className="dachbone-rayita">
            <img src={Rayita} alt="" />
          </div>
        </div>
        <div className="dachbone-text">
          <p>
            DachBone es mucho más que una marca de accesorios para mascotas; es
            la manifestación del amor profundo de nuestros clientes por los
            perros.
          </p>
        </div>
      </div>
      <div className="dachbone-video-contenedor">
        <video
          autoplay="autoplay"
          loop="loop"
          id="video_background"
          preload="auto"
          volume="0"
          className="dachbone-video"
           muted
        >
          <source src={DachboneVideo} type="video/mp4" />
        </video>
        <div className="dachbone-background-gradient" />
        <div className="hide-on-mobile">
          <div className="dachbone-video-titulo">
            <h1>DACHBONE</h1>
          </div>
          <div className="dachbone-video-text">
            <p>
              Dachbone es una marca de accesorios caninos para los mejores
              amigos
            </p>
            <PerritoBlancoDoodle className="perrito-blanco-doodle" />
          </div>
        </div>
        <div className="hide-on-desktop">
          <div className="dachbone-titulo">
            <h5>DACHBONE</h5>
          </div>
          <div className="lowerContainer">
            <div className="dachbone-text">
              <p>
                Abordamos el proyecto de DachBone con un enfoque meticuloso y
                centrado en el cliente. Desde la fase inicial de investigación
                hasta la implementación final, cada paso del proceso se realizó
                con atención al detalle y experiencia técnica.{" "}
                <span style={{ color: "#EB5500" }}>
                  Utilizamos herramientas de diseño de vanguardia y técnicas de
                  branding para capturar la esencia de DachBone y transmitir la
                  pasión por los perros de nuestros clientes.
                </span>{" "}
                Además, nuestro equipo de diseño se esforzó por crear un logo
                distintivo y memorable que reflejara la personalidad única de la
                marca.
              </p>
            </div>
            <div className="dachbone-rayita">
              <p>//</p>
            </div>
          </div>
        </div>
      </div>
      <div className="dachbone-texto">
        <p>
          DachBone es mucho más que una marca de accesorios para mascotas; es la
          manifestación del amor profundo de nuestros clientes por los perros.
        </p>
      </div>
      <div className="dachbone-carpincho-contenedor">
        <AppearTransitions direction="izquierda" className="dachbone-carpincho">
          <img src={PerritoSection3} alt="" />
        </AppearTransitions>
        <AppearTransitions direction="derecha" className="dachbone-carpincho-text">
          <p>
            En Node, nos sentimos honrados de presentar el proyecto del logo de
            DachBone, una colaboración hermosa que surge de nuestra pasión por
            los perros y nuestro compromiso con la creatividad.
          </p>
          <p>
            A través de un proceso creativo enriquecedor, hemos dado forma a un
            logo distintivo que captura la esencia misma de DachBone y transmite
            sus valores fundamentales de elegancia, calidad y la conexión
            especial entre humanos y canes.
          </p>
        </AppearTransitions>
      </div>
      <AppearTransitions direction="derecha" className="dachbone-sobre-el-proyecto-rayita">
        <img src={Rayitanaranja} alt="" />
        <h1>
          SOBRE EL
          <br /> PROYECTO
        </h1>
      </AppearTransitions>
      <AppearTransitions direction="izquierda" className="dachbone-sobre-el-proyecto">
        <h1>
          SOBRE
          <br /> EL PROYECTO
        </h1>
        <p>
          La historia de DachBone comienza con la visión de ofrecer accesorios
          de alta calidad para perros de razas pequeñas, con la aspiración de
          expandirse para abarcar a todos los compañeros caninos en el futuro.
          Nos llena de alegría trabajar con DachBone, un cliente que busca ser
          reconocido como una marca de alta gama, donde la elegancia se combina
          armoniosamente con la funcionalidad en cada producto.
        </p>
        <p>
          Inspirados en una paleta de colores neutros y tonos tierra, realzados
          con pinceladas de verde, azules y pasteles, hemos creado una imagen
          para la marca de accesorios para perros que se integran perfectamente
          en cualquier hogar y estilo de vida.
        </p>
        <p>
          Hemos diseñado meticulosamente el logo de DachBone para transmitir la
          sofisticación y el estilo que distingue a la marca.
        </p>
      </AppearTransitions>
      <div className="dachbone-galeria">
        <img src={DachboneGalleryImage1} alt="" className="china" />
        <img src={DachboneGalleryImage2} alt="" className="viejo" />
        <img src={DachboneGalleryImage3} alt="" className="robotmujer" />
        <img src={DachboneGalleryImage4} alt="" className="manos" />
        <div className="textos">
          <div className="centered">
            <img src={DogFriendlyDoodle} alt="dog friendly" />
            <h1>DOG FRIENDLY</h1>
            <p>
              Los productos de DachBone están dirigidos a personas de espíritu
              creativo y amantes de los perros, quienes valoran la calidad, los
              detalles cuidados y la funcionalidad en cada aspecto de la vida de
              sus fieles compañeros. Nuestra propuesta de logo está diseñada
              para reflejar estos valores y fortalecer el vínculo entre humanos
              y canes.
            </p>
            <p>
              En Node, hemos disfrutado cada etapa del proceso creativo para dar
              vida al nuevo logo de DachBone. Estamos emocionados de colaborar
              con nuestro cliente para ofrecer un diseño distintivo que haga que
              tanto ellos como sus fieles amigos se sientan especiales y
              cuidados.
              <br />{" "}
              <span style={{ color: "#EB5500" }}>
                Agradecemos a DachBone por confiar en nosotros para este hermoso
                proyecto. Esperamos que disfruten tanto de nuestro diseño como
                nosotros disfrutamos creándolo.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
