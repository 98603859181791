import React, { useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./roles.css";

export const Roles = ({ textoIzquierda, textoCentro, textoDerecha, foto }) => {
  const cursorRef1 = useRef();

  const moveCircle = (ref, e) => {
    gsap.to(ref.current, {
      left: e.pageX,
      top: e.pageY,
      ease: "back"
    });
  };

  useEffect(() => {
    if (isMobile) {
      return;
    }
    document.addEventListener("mousemove", (e) => {
      moveCircle(cursorRef1, e);
    });

    return () => {
      document.removeEventListener("mousemove", (e) => {
        moveCircle(cursorRef1, e);
      });
    };
  }, []);

  return (
    <div className="contenedor-roles ">
      <div className="textInputContainer">
        <p className="texto-izquierda blanco-negro">{textoIzquierda}</p>
        <div className="texto-medio">
          <p className="blanco-negro">{textoCentro}</p>
        </div>
        <p className="texto-derecha blanco-negro">{textoDerecha}</p>
      </div>
      <div className="follow" ref={cursorRef1}>
        <img src={foto} alt="" />
      </div>
    </div>
  );
};

export default Roles;
