import React from "react";
import "./fulltex.css";
import Simbolos from "../../../src/svg/simbolos-fulltex.svg";
import Rayitas from "../../../src/svg/rayitas-chotas.svg";
import Balde from "../../img/BaldeNuevo 1.png";
import Membrana from "../../img/baldeTM.webp";
import MembranaNegra from "../../img/texturaNegra.jpg";
import RayitasNaranja from "../../../src/svg/rayitas-chotas-naranjas.svg";
import Fulltexlogo from "../../../src/svg/fulltex-logo-completo.svg";
import Simbolostex from "../../../src/svg/simbolos-fulltex-completo.svg";
import CasaFulltex from "../../img/casaFulltex.webp";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";
import { Button } from "../../componentes/Button/Button";
import Next from "../../componentes/siguiente/next";
export default function Fulltex() {
  return (
    <>
      <div className="fulltex-main">
        <div className="fulltex-contenedor">
          <div className="fulltex-titulo">
            <div className="fulltex-logo">
              <h5>FULLTEX</h5>
              <img src={Simbolos} alt="" className="fulltex-doodle" />
            </div>
            <div className="fulltex-rayita">
              <img src={Rayitas} alt="" />
            </div>
          </div>
          <div className="fulltex-text">
            <p>
              FullTex, establecido en 2023, es un fabricante líder en
              revestimientos plásticos texturizados, incluyendo productos como
              látex y membranas. Con una visión centrada en la calidad y la
              innovación, la empresa se ha{" "}
              <span style={{ color: "#EB5500" }}>
                destacado en un mercado altamente competitivo.
              </span>{" "}
              Este estudio de caso analiza cómo FullTex ha utilizado una
              identidad visual inspirada en la Escuela Bauhaus para fortalecer
              su posición en el mercado y comunicar su compromiso con la
              excelencia.
            </p>
          </div>
          <div className="fulltex-rayita-mobile">
            <img src={Rayitas} alt="" />
          </div>
        </div>

        <div className="contenedor-contexto">
          <div className="titulos-contexto-contenedor">
            <h1>CONTEXTO</h1>
            <img src={Simbolos} alt="" className="fulltex-doodle" />
          </div>
        </div>
        <div className="contenedor-balde">
          <AppearTransitions direction="izquierda">
            <img src={Balde} alt="" className="balde" />
          </AppearTransitions>
          <AppearTransitions direction="derecha" className={"textContainer"}>
            <p>
              <strong>
                En el mercado de revestimientos plásticos, la diferenciación es
                crucial para el éxito. FullTex se enfrentaba al desafío de
                establecer una identidad visual que no solo reflejara la calidad
                de sus productos, sino que también transmitiera su enfoque
                innovador y moderno.
              </strong>
            </p>
          </AppearTransitions>
          <AppearTransitions direction="derecha" className={"rayitasContainer"}>
            <img src={RayitasNaranja} alt="" className="rayitas-naranjas" />
          </AppearTransitions>
        </div>
        <div className="fulltex-contenedor-doodles">
          <AppearTransitions direction="izquierda">
            <div className="fulltex-logo">
              <img src={Fulltexlogo} alt="" />
            </div>
          </AppearTransitions>
          <AppearTransitions direction="izquierda">
            <div className="fulltex-simbolos-completos">
              <img src={Simbolostex} alt="" />
            </div>
          </AppearTransitions>
        </div>
        <div className="desafio-contenedor">
          <AppearTransitions direction="derecha">
            <div className="desafio-text">
              <h1>DESAFÍO</h1>
              <img src={Simbolos} alt="" className="fulltex-doodle" />
            </div>
          </AppearTransitions>
        </div>
        <div className="contenedor-balde-textos">
          <AppearTransitions
            direction="izquierda"
            className="contenedor-text-p"
          >
            <p>
              Crear una identidad visual distintiva que se destacara en un
              mercado saturado. Comunicar la calidad y la innovación de los
              productos de FullTex de manera efectiva. Reflejar el enfoque
              moderno y vanguardista de la empresa en su identidad visual.
              Soluciones:
            </p>
            <p>
              <h2>Inspiración en la Escuela Bauhaus:</h2>
              FullTex adoptó principios de diseño de la Escuela Bauhaus,
              enfocándose en líneas limpias, formas geométricas y colores
              audaces. Diseño de Identidad Visual: Se desarrolló un nuevo
              logotipo que incorporaba tipografía moderna y formas geométricas
              simplificadas.
            </p>
            <img src={Simbolostex} alt="" />
            <p>
              <h2>implementacion coherente:</h2>
              La nueva identidad visual se aplicó en todos los materiales de
              marketing, desde folletos hasta el sitio web, creando una
              experiencia de marca consistente. Resultados:
            </p>
            <p>
              <h2>Respuesta Positiva:</h2>
              La nueva identidad visual recibió elogios tanto de clientes
              existentes como de nuevos clientes potenciales.
            </p>
            <p>
              <h2>Diferenciación en el Mercado:</h2>
              FullTex se destacó en un mercado saturado gracias a su enfoque
              único en el diseño.
            </p>
          </AppearTransitions>
          <AppearTransitions
            direction="derecha"
            className="contenedor-balde-membrana first-on-mobile"
          >
            <div
              className="muestra-membrana"
              style={{ backgroundImage: `url(${MembranaNegra})` }}
            />
          </AppearTransitions>
          <div className="contenedor-balde-membrana">
            <AppearTransitions direction="derecha">
              <img src={Membrana} alt="" className="balde" />
            </AppearTransitions>
          </div>
        </div>
        <div className="conclusion-titulo">
          <div className="conclusion-h1-img">
            <h1>CONCLUSION</h1>
            <img src={Simbolos} alt="" className="fulltex-doodle" />
          </div>
        </div>
        <div className="conclusion-contenido">
          <p>
            El caso de FullTex demuestra el poder de una identidad visual sólida
            y coherente en la construcción de una marca exitosa. Al adoptar una
            estrategia inspirada en la Escuela Bauhaus, la empresa logró
            diferenciarse en un mercado competitivo y comunicar su compromiso
            con la calidad y la innovación. Este estudio destaca la importancia
            de la creatividad y el diseño en la creación de una marca memorable
            y exitosa.
          </p>
          <div
            alt=""
            className="casa-fulltex"
            style={{ backgroundImage: `url(${CasaFulltex})` }}
          />
        </div>
        <div className="fulltex-link">
          <Button
            width="15rem"
            height="3.5rem"
            bgColor={"white"}
            bgHoverColor={"black"}
            containerClassname="proyectosButton"
          >
            <a href="https://www.fulltex.com.ar/" target="_blank">
              <h1>WWW.FULLTEX.COM.AR</h1>
            </a>{" "}
          </Button>
        </div>
        <Next siguiente="/baires" />
      </div>
    </>
  );
}
