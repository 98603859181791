import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./carrusel.css";
import Baires from "../../img/homeCard2.webp";
import Boscova from "../../img/homeCard4.webp";
import Fulltex from "../../img/homeCard1.webp";
import Iaproyectos from "../../img/homeCard3.webp";
import Dachbone from "../../img/homeCard5.webp";
import FulltexMobile from "../../img/homeCard1Mobile.png";
import BairesMobile from "../../img/homeCard2Mobile.png";
import IaproyectosMobile from "../../img/homeCard3Mobile.png";
import BoscovaMobile from "../../img/homeCard4Mobile.png";
import DachboneMobile from "../../img/homeCard5Mobile.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import DelayLink from "../SwitchTransitions/DelayLink";

gsap.registerPlugin(ScrollTrigger, useGSAP);

const Carrusel = () => {
  const cardsContainerRef = useRef(null);
  const imagesArray = [Fulltex, Baires, Iaproyectos, Boscova, Dachbone];
  const [loadedImages, setLoadedImages] = useState({});
  const [ready, setReady] = useState(false);

  function handleImageLoaded(src) {
    const newLoadedImages = {
      ...loadedImages,
      [src]: true,
    };

    // Check if all images is loaded
    const newLoadedImagesArr = Object.keys(newLoadedImages);
    if (
      newLoadedImagesArr.length === imagesArray.length &&
      imagesArray.every((v) => newLoadedImagesArr.includes(v))
    ) {
      console.log("All images loaded");
      setReady(true);
    }

    setLoadedImages(newLoadedImages);
  }

  useEffect(() => {
    if (ready) {
      ScrollTrigger.refresh();
    }
  }, [ready]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const cards = gsap.utils.toArray(".card");
      cards.forEach((card, index) => {
        if (index === cards.length - 1) {
          return;
        }
        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: () => `top top+=200`,
            end: () => `top top-=250`,
            scrub: true,
            invalidateOnRefresh: true,
          },
          ease: "none",
          scale: 0.75,
          transformOrigin: "center center",
        });

        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: () => `top top-=50`,
            end: () => `top top`,
            scrub: 1,
            invalidateOnRefresh: true,
          },
          ease: "none",
          filter: "blur(8px)",
        });
        ScrollTrigger.create({
          trigger: card,
          start: "top top",
          pin: true,
          pinSpacing: false,
          id: "pin",
          end: () =>
            "+=" +
            ((card.clientHeight + 100) * 4 -
              (card.clientHeight + 100) * index) +
            "px",
          invalidateOnRefresh: true,
        });
      });

      ScrollTrigger.refresh();
    }, cardsContainerRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className="cards" ref={cardsContainerRef}>
      <div className="card" data-index="0">
        <DelayLink to="/fulltex">
          <div className="card__inner">
            <div className="card__image-container">
              <div
                className="card__image"
                style={{ backgroundImage: `url(${Fulltex})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(Fulltex);
                }}
              />
              <div
                className="card__image mobile_card"
                style={{ backgroundImage: `url(${FulltexMobile})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(FulltexMobile);
                }}
              />
            </div>
          </div>
        </DelayLink>
      </div>

      <div className="card" data-index="0">
        <DelayLink to="/baires">
          <div className="card__inner">
            <div className="card__image-container">
              <div
                className="card__image"
                style={{ backgroundImage: `url(${Baires})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(Baires);
                }}
              />
              <div
                className="card__image mobile_card"
                style={{ backgroundImage: `url(${BairesMobile})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(BairesMobile);
                }}
              />
            </div>
          </div>
        </DelayLink>
      </div>
      <div className="card" data-index="0">
        <DelayLink to="/ia-proyectos">
          <div className="card__inner">
            <div className="card__image-container">
              <div
                className="card__image"
                style={{ backgroundImage: `url(${Iaproyectos})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(Iaproyectos);
                }}
              />
              <div
                className="card__image mobile_card"
                style={{ backgroundImage: `url(${IaproyectosMobile})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(IaproyectosMobile);
                }}
              />
            </div>
          </div>
        </DelayLink>
      </div>
      <div className="card" data-index="0">
        <DelayLink to="/boscova">
          <div className="card__inner">
            <div className="card__image-container">
              <div
                className="card__image"
                style={{ backgroundImage: `url(${Boscova})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(Boscova);
                }}
              />
              <div
                className="card__image mobile_card"
                style={{ backgroundImage: `url(${BoscovaMobile})` }}
                alt=""
                onLoad={() => {
                  handleImageLoaded(BoscovaMobile);
                }}
              />
            </div>
          </div>
        </DelayLink>
      </div>
      <div className="card" data-index="0">
        <DelayLink to="/dachbone">
          <div className="card__inner">
            <div
              className="card__image-container"
            >
              <div
                className="card__image"
                alt=""
                style={{ backgroundImage: `url(${Dachbone})` }}
                onLoad={() => {
                  handleImageLoaded(Dachbone);
                }}
              />
              <div
                className="card__image mobile_card"
                alt=""
                style={{ backgroundImage: `url(${DachboneMobile})` }}
                onLoad={() => {
                  handleImageLoaded(DachboneMobile);
                }}
              />
            </div>
          </div>
        </DelayLink>
      </div>
    </div>
  );
};

export default Carrusel;
