import React from "react";
import "./footer.css";
import { AiOutlineTrademark } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import Botella from "../../img/botella.png";
import Robotmujer from "../../img/mujer-robot-ia.png";
import DachboneMobile from "../../img/dachboneGalleryMobileImage.png";

export const Footer = ({ isLightMode }) => {
  const location = useLocation();
  const paginasNegras = [
    "/baires",
    "/ia-proyectos",
    "/fulltex",
    "/boscova",
    "/dachbone",
  ];
  const paginasNegrasEnMobile = [];
  const deshabilitarEn = ["/trabajos"];
  const footerWithImage = ["/boscova", "/ia-proyectos", "/dachbone"];
  const footerClassnames = classNames("footer", {
    "footer-negro": paginasNegras.includes(location.pathname) || isLightMode,
    "footer-negro-mobile":
      paginasNegrasEnMobile.includes(location.pathname) || isLightMode,
    "footer-with-image": footerWithImage.includes(location.pathname),
  });

  return (
    <>
      {!deshabilitarEn.includes(location.pathname) && (
        <div className={footerClassnames}>
          {location.pathname === "/boscova" && (
            <>
              <div
                className="footerImagen"
                style={{ backgroundImage: `url(${Botella})` }}
              />
              <div className="footerGradient" />
            </>
          )}
          {location.pathname === "/ia-proyectos" && (
            <>
              <div
                className="footerImagen"
                style={{ backgroundImage: `url(${Robotmujer})` }}
              />
              <div className="footerGradient" />
            </>
          )}
          {location.pathname === "/dachbone" && (
            <>
              <div
                className="footerImagen"
                style={{ backgroundImage: `url(${DachboneMobile})` }}
              />
              <div className="footerGradient" />
            </>
          )}
          <a href="#" className="volverAlHome">
            <p className="volver">
              Volver al TOP <IoIosArrowUp />
            </p>
          </a>
          <div className="laR">
            <p>2024 Node Digital Studio</p>
            <AiOutlineTrademark />
          </div>
          <div className="caja-redes">
            <div className="segui">
              <p>seguinos</p>
              <div className="sharef">
                <BsShareFill />
              </div>
            </div>
            <div className="redes">
              <Link className="facebook"
                to="https://www.facebook.com/Nodedigitalstudio?locale=es_LA"
                target="_blank"
              >
                <FaFacebookSquare />
              </Link>
              <Link className="linkedin"
                to="https://www.linkedin.com/company/node-digital-studio/"
                target="_blank"
              >
                {" "}
                <FaLinkedin />{" "}
              </Link>
              <Link className="instagram"
                to="https://www.instagram.com/nodedigitalstudio/"
                target="_blank"
              >
                {" "}
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
