import React from "react";
import "./boscova.css";
import Rayita from "../../svg/rayitas-chotas.svg";
import Pirata from "../../img/pirata.png";
import Carpincho from "../../img/carpincho.png";
import Viejotomando from "../../img/viejo-tomando.png";
import Grisboscova from "../../img/boscova-gris.png";
import Buzo from "../../img/buzo-boscova.png";
import Botella from "../../img/botella.png";
import Rayitanaranja from "../../svg/rayitas-chotas-naranjas.svg";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";
import Next from "../../componentes/siguiente/next";

export default function boscova() {
  return (
    <>
      <div className="boscova-main">
        <div className="boscova-contenedor">
          <div className="boscova-titulo">
            <h5>
              BOSCOVA
              <br />
              BEER
            </h5>
            <div className="boscova-rayita">
              <img src={Rayita} alt="" />
            </div>
          </div>
          <div className="boscova-text">
            <p>
              Boscova Beer comenzó como un proyecto educativo que rápidamente se
              transformó en uno de nuestros clientes más destacados en el
              estudio.{" "}
              <span style={{ color: "#EB5500" }}>
                Desde la creación de su nombre y logo hasta el desarrollo
                completo de su presencia web, acompañamos a Boscova en cada
                etapa de la construcción de su marca.
              </span>{" "}
              Implementamos procesos eficientes, perfeccionados con el tiempo y
              siempre con la colaboración constante del cliente, garantizando
              que cada aspecto de la marca reflejara fielmente sus valores y
              visión.
            </p>
          </div>
        </div>
        <div
          className="boscova-imagen-contenedor"
          style={{ backgroundImage: `url(${Pirata})` }}
        >
          <div className="boscova-background-gradient" />
          <div className="hide-on-mobile">
            <div className="boscova-imagen-titulo">
              <h1>
                CERVERÍA
                <br />
                ARTESANAL
              </h1>
            </div>
            <div className="boscova-imagen-text">
              <p>
                Boscova es una cervecería de
                <br />
                Córdoba Argentina
              </p>
            </div>
          </div>
          <div className="hide-on-desktop">
            <div className="boscova-contenedor">
              <div className="boscova-titulo">
                <h5>
                  BOSCOVA
                  <br />
                  BEER
                </h5>
              </div>
              <div className="boscova-text">
                <p>
                  Boscova Beer comenzó como un proyecto educativo que
                  rápidamente se transformó en uno de nuestros clientes más
                  destacados en el estudio.{" "}
                  <span style={{ color: "#EB5500" }}>
                    Desde la creación de su nombre y logo hasta el desarrollo
                    completo de su presencia web, acompañamos a Boscova en cada
                    etapa de la construcción de su marca.
                  </span>{" "}
                  Implementamos procesos eficientes, perfeccionados con el
                  tiempo y siempre con la colaboración constante del cliente,
                  garantizando que cada aspecto de la marca reflejara fielmente
                  sus valores y visión.
                </p>
              </div>
              <div className="boscova-rayita">
                <p>//</p>
              </div>
            </div>
          </div>
        </div>
        <div className="boscova-texto">
          <p>Boscova es una cervecería de Córdoba Argentina</p>
        </div>
        <div className="boscova-carpincho-contenedor">
          <div className="boscova-carpincho">
            <AppearTransitions direction="izquierda">
              <img src={Carpincho} alt="" />
            </AppearTransitions>
          </div>
          <AppearTransitions
            direction="derecha"
            className="boscova-carpincho-text"
          >
            <p>
              El proceso de creación de la marca Boscova Beer fue un emocionante
              viaje de colaboración y creatividad. Comenzamos nuestra travesía
              con un proyecto educativo que nos desafió a explorar nuevos
              horizontes en el mundo de la cerveza artesanal. A medida que
              desarrollábamos y refinábamos nuestras ideas, Boscova surgió como
              un cliente potencial y rápidamente se convirtió en una parte
              integral de nuestro estudio.
            </p>
            <p>
              Desde el principio, nos sumergimos en el proceso de dar vida a la
              identidad de la marca. Esto incluyó la meticulosa creación del
              nombre y el diseño del logo, donde cada elemento fue
              cuidadosamente seleccionado para capturar la esencia única de
              Boscova Beer. Trabajamos en estrecha colaboración con el cliente,
              aprovechando su visión y retroalimentación para orientar cada paso
              del camino.
            </p>
          </AppearTransitions>
        </div>
        <AppearTransitions
          direction="derecha"
          className="boscova-sobre-el-proyecto-rayita"
        >
          <img src={Rayitanaranja} alt="" />

          <h1>
            EL
            <br /> PROYECTO
          </h1>
        </AppearTransitions>
        <AppearTransitions
          direction="izquierda"
          className="boscova-sobre-el-proyecto"
        >
          <h1>EL PROYECTO</h1>
          <p>
            Para las etiquetas de las cervezas, colaboramos estrechamente con el
            equipo de Boscova para desarrollar diseños que capturaran la
            historia, el sabor y la personalidad de cada variedad de cerveza.
            Inspirándonos en la artesanía y los ingredientes de las cervezas,
            creamos etiquetas llamativas y auténticas que destacan en los
            estantes de las tiendas y atraen la atención de los consumidores.
          </p>
          <p>
            Además de las etiquetas de las cervezas, nos encargamos de diseñar
            una amplia gama de productos de merchandising para Boscova, como
            camisetas, gorras, vasos y posavasos.{" "}
            <span style={{ color: "#EB5500" }}>
              Cada pieza fue cuidadosamente elaborada para reflejar la marca y
              crear una experiencia cohesiva para los clientes,
            </span>{" "}
            tanto en la tienda como en casa.
          </p>
        </AppearTransitions>
        <div className="boscova-galeria">
          <img src={Viejotomando} alt="" className="viejo" />
          <img src={Grisboscova} alt="" className="boscova" />
          <img src={Buzo} alt="" className="buzo" />
          <img src={Botella} alt="" className="botella" />
          <div className="textos">
            <div className="centered">
              <h1>PROCESOS</h1>
              <p>
                En cuanto a la presencia online, creamos una plataforma web
                completa que funcionara como el corazón digital de la marca.
                Cada aspecto de la web, desde el diseño y la navegación hasta la
                funcionalidad y el contenido, fue cuidadosamente diseñado para
                transmitir la historia y los valores de Boscova Beer, y para
                ofrecer a los clientes una experiencia interactiva y
                cautivadora.
              </p>
              <p>
                En resumen, el proyecto para Boscova Beer fue una emocionante
                aventura creativa que nos permitió dar vida a una marca vibrante
                y emocionante en el mundo de la cerveza artesanal. A través de
                la colaboración, la creatividad y el compromiso con la calidad,
                logramos crear una identidad visual y una presencia online que
                capturaron la esencia única de Boscova y resonaron con su
                audiencia.
              </p>
            </div>
          </div>
        </div>
        <Next  siguiente="/dachbone" />
      </div>
    </>
  );
}
