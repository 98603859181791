import React, { useRef, useState } from "react";
import "./landing.css";
import FAQItem from "../../componentes/faqitem/faqitem";
import Whatsapp from "../../svg/whatsapp.svg";
import video1 from "../../img/landing/videos/video4.mp4";
import svg1 from "../../img/landing/pc-1.svg";
import svg2 from "../../img/landing/apple.svg";
import svg3 from "../../img/landing/pc-2.svg";
import svg4 from "../../img/landing/herramientas.svg";
import svg5 from "../../img/landing/cuadradito-redondo.svg";
import svg6 from "../../img/landing/lapicera.svg";
import foco from "../../img/landing/Foquito.svg";
import carita from "../../img/landing/CaritaSatanica.svg";
import like from "../../img/landing/mdi_like.svg";
import dislike from "../../img/landing/mdi_dislike.svg";
import flechacirculo from "../../img/landing/Group 630.svg";
import video2 from "../../img/landing/videos/ContenedorVideo.mp4";
import fb from "../../img/landing/Frame 553.svg";
import lin from "../../img/landing/Vector.svg";
import ig from "../../img/landing/Frame 555.svg";
import node from "../../img/landing/LogoSvg.svg";
import logo from "../../img/landing/Modo_de_aislamiento.svg";
import video3 from "../../img/landing/videos/Preview.mp4";
import check from "../../svg/checkPoint.svg";
import { ImagenCambiaEnHover } from "../../componentes/imagenCambiaEnHover/ImagenCambiaEnHover";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";
import Hamburger from "hamburger-react";
import DelayLink from "../../componentes/SwitchTransitions/DelayLink";
import { useLenis } from "@studio-freight/react-lenis";
import FotoCliente1 from "../../img/landing/FotoFulltex.png";
import FotoCliente2 from "../../img/landing/FotoBoscova.png";
import FotoCliente3 from "../../img/landing/FotoCliente3.png";
import PortadaVideoMobile from "../../img/landing/videos/portadaMobile.mp4";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import axios from "axios";
import Condicional from "../../utils/Condicional";
import { ToastContainer, toast } from "react-toastify";
import Snowfall from "react-snowfall";
import witch from "../../img/witch.png";
import ghost from "../../img/ghost.png";
import cat from "../../img/halloween-cat.png";
import pumpkin from "../../img/halloween-pumpkin.png";
import bats from "../../img/moon-bats.png";
import spiderweb from "../../img/spiderweb.png";
const snowflake1 = document.createElement("img");
snowflake1.src = witch;
const snowflake2 = document.createElement("img");
snowflake2.src = ghost;
const snowflake3 = document.createElement("img");
snowflake3.src = cat;
const snowflake4 = document.createElement("img");
snowflake4.src = pumpkin;
const snowflake5 = document.createElement("img");
snowflake5.src = bats;
const snowflake6 = document.createElement("img");
snowflake6.src = spiderweb;

const images = [
  snowflake1,
  snowflake2,
  snowflake3,
  snowflake4,
  snowflake5,
  snowflake6,
];
export const Landing = () => {
  gsap.registerPlugin(useGSAP);
  const [currentCard, setCurrentCard] = useState(0);
  const [currentCardText, setCurrentCardText] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const lenis = useLenis();
  const textRef = useRef(null);
  const navMenuRef = useRef(null);
  const texts = [
    "Trabajar con Node Digital Studio es una experiencia fantástica. Entendieron nuestra visión desde el primer momento y la convirtieron en una identidad visual que realmente somos. Su enfoque en la calidad y la innovación ha fortalecido nuestra posición en el mercado. ¡Los recomendamos totalmente! - Carlos y Leo de Fulltex",
    "Node Digital Studio ha sido un gran aliado en la creación de nuestra marca. Nos ayudaron con todo, desde el nombre y el logo hasta nuestra presencia digital. Su equipo es creativo, profesional y muy colaborativo. Gracias a ellos, nuestra marca refleja  nuestros valores. - jorge de Boscova ",
    "El equipo de Node Digital Studio diseñó una website que realmente captura la esencia de mi trabajo en terapias y retiros. Su atención al detalle y compromiso con la personalización resultaron en una plataforma funcional que comunica mi enfoque de ayudar a las personas a vivir una vida plena y equilibrada. - Ana Belén Tomé ",
  ];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    reset,
    formState,
  } = useForm({
    defaultValues: {
      nombre: "",
      empresa: "",
      pais: "",
      email: "",
      mensaje: "",
      origen: "Proyectos",
    },
  });

  const handleCardChange = (index) => {
    setCurrentCard(index);
    setIsFirstRender(false);
    gsap
      .timeline()
      .to(textRef.current, {
        duration: 0.5,
        filter: "blur(10px)",
        onComplete: () => {
          setCurrentCardText(index);
        },
      })
      .to(textRef.current, {
        duration: 0.5,
        filter: "blur(0px)",
      });
  };

  useGSAP(() => {
    gsap.set(navMenuRef.current, { scaleY: 0 });
  }, []);

  useGSAP(() => {
    if (!isMenuOpen) {
      gsap.to(navMenuRef.current, {
        duration: 0.5,
        scaleY: 0,
        ease: "power1.inOut",
        transformOrigin: "top",
      });
    } else {
      gsap.to(navMenuRef.current, {
        duration: 0.5,
        scaleY: 1,
        ease: "power1.inOut",
        transformOrigin: "top",
      });
    }
  }, [isMenuOpen]);

  const getNextIndex = (index) => {
    if (index === 2) {
      return 0;
    } else {
      return index + 1;
    }
  };

  const getPreviousIndex = (index) => {
    if (index === 0) {
      return 2;
    } else {
      return index - 1;
    }
  };

  const onSubmit = (data) => {
    console.log("[TEST]", data);
    toast.info("Enviando formulario...");
    axios
      .post(
        "https://gin2o0p902.execute-api.sa-east-1.amazonaws.com/landing_node_mail",
        data
      )
      .then(() => {
        toast.success("Formulario enviado");
        reset();
      })
      .catch(() => {
        toast.error("Error al enviar el formulario");
      });
  };

  const onHandleError = (data) => {
    console.log("[TEST]", data);
    toast.error("Error al enviar el formulario");
  };

  return (
    <div className="landing-main">
      <div className="landing-barra">
        <div className="nav-icon">
          <DelayLink to="/">
            <img src={logo} alt="" />
          </DelayLink>
        </div>
        <div className="navegacion-barra">
          <button onClick={() => lenis.scrollTo("#nosotros")}>
            <p className="text-1">Sobre nosotros</p>
          </button>
          <button onClick={() => lenis.scrollTo("#servicios")}>
            <p className="text-2">Servicios</p>
          </button>
          <DelayLink to="/trabajos">
            <p className="text-3">Proyectos</p>
          </DelayLink>
          <button onClick={() => lenis.scrollTo("#planes")}>
            <p className="text-4">Planes</p>
          </button>
        </div>
      </div>
      <div className="landing-barra-mobile">
        <div className="nav-content">
          <DelayLink to="/">
            <img src={logo} alt="" />
          </DelayLink>
          <button
            className="menu-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Hamburger
              size={20}
              className="menu"
              toggled={isMenuOpen}
              color={"white"}
            />
          </button>
        </div>
        <div className="menu-mobile" ref={navMenuRef}>
          <button onClick={() => lenis.scrollTo("#nosotros")}>
            <p className="text">Sobre nosotros</p>
          </button>
          <button onClick={() => lenis.scrollTo("#servicios")}>
            <p className="text">Servicios</p>
          </button>
          <DelayLink to="/trabajos">
            <p className="text">Proyectos</p>
          </DelayLink>
          <button onClick={() => lenis.scrollTo("#planes")}>
            <p className="text">Planes</p>
          </button>
        </div>
      </div>
      <div className="landing-video">
        <video
          src={isMobile ? PortadaVideoMobile : video3}
          autoPlay
          preload="none"
          muted
          loop
        ></video>
      </div>
      <div className="landing-trabajemos">
        <AppearTransitions className="textos" direction="izquierda">
          <p className="text-1">¡trabajemos</p>
          <p className="text-2">juntos!</p>
          <p className="text-3">
            Combinamos creatividad e innovación para ofrecer soluciones
            digitales personalizadas.
          </p>
          <a href="https://wa.link/3f15un" target="_blank">
            <div className="boton">
              <p>contactanos</p>
              <img src={Whatsapp} alt="" />
            </div>
          </a>
        </AppearTransitions>
        <AppearTransitions className="imagen" direction="derecha" />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onHandleError)}
        className="mantenimiento-formulario"
      >
        <div className="formulario-borde">
          <div className="arriba">
            <div className="izquierda">
              <p className="texto-1">
                ENCENDAMOS <span>IDEAS</span>
              </p>
              <p className="texto-2">
                Solicita una auditoría gratuita y descubre cómo optimizar tu
                sitio o software
              </p>
            </div>
            <div className="derecha">
              <p className="texto-1">
                Comunícate con nosotros de forma directa para que podamos
                ayudarte en la optimización y renovación de tu empresa o negocio
              </p>
              <p className="texto-2">nodedigitalstudio@gmail.com</p>
            </div>
          </div>
          <div className="medio-arriba">
            <div className="izquierda">
              <div className="arriba-nombre">
                <p className="texto-1">¿Cuál es tu nombre completo? </p>
                <p className="texto-2">
                  Requerido<span>*</span>
                </p>
              </div>
              <input
                type="text"
                {...register("nombre", {
                  required: true,
                  pattern: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/,
                })}
              />
            </div>
            <div className="derecha">
              <div className="arriba-nombre">
                <p className="texto-1">
                  ¿Cuál es el nombre de tu empresa o negocio?
                </p>
                <p className="texto-2">(opcional)</p>
              </div>
              <input
                type="text"
                {...register("empresa", {
                  required: false,
                  pattern: /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/,
                })}
              />
            </div>
          </div>
          <div className="medio-abajo">
            <div className="izquierda">
              <div className="arriba-nombre">
                <p className="texto-1">¿tu mail o mail de la empresa? </p>
                <p className="texto-2">
                  Requerido<span>*</span>
                </p>
              </div>
              <input
                type="email"
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </div>
            <div className="derecha">
              <div className="arriba-nombre">
                <p className="texto-1">¿En que país estas? </p>
                <p className="texto-2">
                  Requerido<span>*</span>
                </p>
              </div>
              <input
                type="text"
                {...register("pais", {
                  required: true,
                  pattern: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/,
                })}
              />
            </div>
          </div>
          <div className="abajo">
            <p className="texto-1">Tu mensaje</p>
            <textarea
              {...register("mensaje", { required: true })}
              name="mensaje..."
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </div>
        <div className="botones-checkpoints">
          <div className="izquierda">
            <div className="captcha-1">
              <button type="checkbox"></button>
            </div>
            <p>SOY HUMANO (CAPTCHA)</p>
          </div>
          <div className="derecha">
            <div className="arriba">
              <div className="check-1">
                <button disabled>Tu Nombre</button>
                <Condicional test={getFieldState("nombre", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-2">
                <button disabled>Nombre de tu empresa</button>
                <Condicional test={getFieldState("empresa", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-3">
                <button disabled>Mail</button>
                <Condicional test={getFieldState("email", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-4">
                <button disabled>País</button>
                <Condicional test={getFieldState("pais", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-5">
                <button disabled>Tu Mensaje</button>
                <Condicional test={getFieldState("mensaje", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
            </div>
            <div className="abajo" onClick={handleSubmit(onSubmit)}>
              <div className="contenedor-boton">
                <button type="submit">ENVIAR</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="landing-encendamos" id="nosotros">
        <AppearTransitions className="texto" direction={"izquierda"}>
          <p>
            En Node Digital Studio, estamos en una misión apasionante: fusionar
            creatividad y tecnología para ofrecer soluciones digitales
            innovadoras y atractivas. Somos un equipo de diseñadores,
            desarrolladores y estrategas apasionados que se esfuerzan por
            superar los límites de lo convencional.
          </p>
        </AppearTransitions>
        <AppearTransitions className="imagen" direction={"derecha"} />
      </div>
      <div className="landing-colaboremos">
        <AppearTransitions className="titulo" direction={"izquierda"}>
          <p className="text-1">
            ¡Colaboremos juntos para dar vida a tus ideas!
          </p>
        </AppearTransitions>
        <div className="video">
          <video src={video1} preload="auto" autoPlay muted loop></video>
        </div>
        <AppearTransitions className="parrafo" direction={"derecha"}>
          <p>
            Ofrecemos una amplia variedad de servicios digitales, desde el
            diseño y desarrollo web hasta estrategias de branding y UX design
            aplicado. Nos especializamos en la personalización para asegurar que
            cada proyecto refleje tu visión única.
          </p>
        </AppearTransitions>
      </div>
      <div className="landing-tarjetas">
        <div className="tarjetas-arriba">
          <div className="tarjeta-1">
            <div className="contenido">
              <p>Desarrollo Web</p>
              <img src={svg1} alt="" />
            </div>
            <p className="descripcion">
              Diseñamos sitios web funcionales y atractivos, optimizados para
              ofrecer una excelente experiencia de usuario.
            </p>
          </div>
          <div className="tarjeta-2">
            <div className="contenido">
              <p>Brand Strategy</p>
              <img src={svg2} alt="" />
            </div>
            <p className="descripcion">
              Creamos estrategias de marca aplicadas a websites, ayudando a
              posicionar y diferenciar tu negocio en el mercado.
            </p>
          </div>
          <div className="tarjeta-3">
            <div className="contenido">
              <p>E-Commerce</p>
              <img src={svg3} alt="" />
            </div>
            <p className="descripcion">
              Construimos tiendas online robustas y personalizadas para
              maximizar tus ventas y mejorar la experiencia de compra de tus
              clientes.
            </p>
          </div>
        </div>
        <div className="tarjetas-abajo">
          <div className="tarjeta-4">
            <div className="contenido">
              <p>Mantenimiento</p>
              <img src={svg4} alt="" />
            </div>
            <p className="descripcion">
              Ofrecemos mantenimiento continuo para que tu sitio web funcione
              sin problemas y esté siempre actualizado.
            </p>
          </div>
          <div className="tarjeta-5">
            <div className="contenido">
              <p>diseño grafico</p>
              <img src={svg5} alt="" />
            </div>
            <p className="descripcion">
              Desarrollamos elementos visuales que comunican de manera efectiva
              la identidad de tu marca.
            </p>
          </div>
          <div className="tarjeta-6">
            <div className="contenido">
              <p>Diseño ux/ui</p>
              <img src={svg6} alt="" />
            </div>
            <p className="descripcion">
              Diseñamos interfaces intuitivas y atractivas que mejoran la
              interacción del usuario con tu producto.
            </p>
          </div>
        </div>
      </div>
      <div className="landing-fulltex">
        <div className="texto-2024">
          <p className="text-1">2024</p>
          <p className="text-2">
            Nuestros Trabajos y <strong>Proyectos</strong>
          </p>
        </div>
        <div className="imagen-texto">
          <AppearTransitions className="div-img" direction="izquierda" />

          <AppearTransitions className="div-p" direction="derecha">
            <p className="titulo">FULLTEX</p>
            <p className="parrafo">
              FullTex, fundado en 2023, se ha consolidado como un líder en
              revestimientos plásticos texturizados y ha fortalecido su posición
              en el mercado con una identidad visual inspirada en la Escuela
              Bauhaus, reflejando su compromiso con la calidad y la innovación.
            </p>
          </AppearTransitions>
        </div>
      </div>
      <div className="landing-baires">
        <AppearTransitions className="baires-texto" direction="izquierda">
          <p className="titulo">BAIRES HOUSE OF MUSIC</p>
          <p className="parrafo">
            Baires House of Music, fue creado para un programa de estudios de
            Front end. Se implemento todas las categorías y herramientas
            esenciales de un e-commerce, fue el proyecto que hace unos años unio
            al equipo de Node
          </p>
        </AppearTransitions>
        <AppearTransitions className="baires-imagen" direction="derecha" />
      </div>
      <div className="landing-boscova">
        <AppearTransitions className="boscova-imagen" direction="izquierda" />

        <AppearTransitions className="boscova-texto" direction="derecha">
          <p className="titulo">BOSCOVA</p>
          <p className="parrafo">
            Boscova Beer, inicialmente un proyecto educativo, se convirtió en un
            destacado cliente para nuestro estudio, donde desde el nombre y logo
            hasta la presencia web, colaboramos estrechamente para asegurar que
            cada aspecto de la marca reflejara sus valores y visión.
          </p>
        </AppearTransitions>
      </div>
      <div className="landing-ana">
        <AppearTransitions className="ana-texto" direction="izquierda">
          <p className="titulo">ANA BELEN TOME</p>
          <p className="parrafo">
            La website de Ana Belén Tomé, especialista en terapias holísticas en
            España, está diseñada para reflejar su enfoque en ayudar a las
            personas a vivir una vida plena y equilibrada.
          </p>
        </AppearTransitions>
        <AppearTransitions className="ana-imagen" direction="derecha" />
      </div>

      <div className="landing-planes" id="planes">
        <div className="planes-texto">
          <p className="text-1">PLANES</p>
          <p className="text-2">y promociones</p>
        </div>
        <div className="planes-tarjetas">
          <div className="plan-1">
            <div className="arriba">
              <p className="text-1">BASIC</p>
              <p className="text-2">Plan</p>
              <p className="text-3">
                Este plan incluye todo lo esencial para crear una presencia en
                línea efectiva. Ofrecemos diseño de páginas web responsivas,
                personalización de plantillas
              </p>
            </div>
            <div className="abajo">
              <div className="precios">
                <div className="original">
                  <p className="text-1">ORIGINAL</p>
                  <p className="text-2">2.500 €</p>
                  <hr className="linea" />
                </div>
                <div className="descuento">
                  <p className="text-1">descuento</p>
                  <p className="text-2">1.500 €</p>
                </div>
              </div>
              <p className="frase">Ahorra y obtene una pagina web unica</p>
            </div>
          </div>
          <div className="plan-2">
            <div className="arriba">
              <p className="text-1">PRO</p>
              <p className="text-2">Plan</p>
              <p className="text-3">
                El Plan Pro está diseñado para quienes buscan una presencia web
                más sofisticada y funcional. Incluye diseño web personalizado,
                integración de e-commerce, optimización avanzada de SEO, y
                soporte técnico continuo.
              </p>
            </div>
            <div className="abajo">
              <div className="precios">
                <div className="original">
                  <p className="text-1">ORIGINAL</p>
                  <p className="text-2">3.500 €</p>
                  <hr className="linea" />
                </div>
                <div className="descuento">
                  <p className="text-1">descuento</p>
                  <p className="text-2">2.500 €</p>
                </div>
              </div>
              <p className="frase">Ahorra y obtene una pagina web unica</p>
            </div>
          </div>
          <div className="plan-3">
            <div className="arriba">
              <p className="text-1">BUSINESS</p>
              <p className="text-2">Plan</p>
              <p className="text-3">
                El Plan Business ofrece un enfoque integral para empresas que
                requieren una presencia digital profesional y completa. Incluye
                diseño web a medida, funcionalidades personalizadas, gestión de
                contenido, integración con sistemas empresariales, y soporte
                prioritario.
              </p>
            </div>
            <div className="abajo">
              <div className="precios">
                <div className="original">
                  <p className="text-1">ORIGINAL</p>
                  <p className="text-2">5000 €</p>
                  <hr className="linea" />
                </div>
                <div className="descuento">
                  <p className="text-1">descuento</p>
                  <p className="text-2">3.500 €</p>
                </div>
              </div>
              <p className="frase">Ahorra y obtene una pagina web unica</p>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-starter" id="servicios">
        <div className="starter-contenedor">
          <div className="starter-textos">
            <p className="text-1">starter pack</p>
            <p className="text-2">pack</p>
            <p className="text-3">Cupos limitados</p>
            <p className="text-4">
              Obtén tu primer website de 5 secciones más una landing page de
              ventas, diseñado a medida para tu branding. Incluye diseño
              responsive para PC y móvil y aplicación estratégica de UX/UI y
              branding.
            </p>
          </div>
          <div className="starter-foco">
            <img src={foco} alt="" />
            <p className="text-1">Starter pack</p>
            <p className="text-2">950 €</p>
            <p className="text-3">Ahorra y obtene una pagina web unica</p>
          </div>
          <div className="boton">
            <a href="https://wa.link/cmn2hs" target="_blank">
              <p>me interesa este servicio</p>
            </a>
          </div>
        </div>
      </div>
      <div className="starter-mobile">
        <div className="starter-contenedor">
          <div className="starter-textos">
            <p className="text-1">starter pack</p>
            <p className="text-2">
              pack<span>Cupos limitados</span>
            </p>
            <p className="text-4">
              Obtén tu primer website de 5 secciones más una landing page de
              ventas, diseñado a medida para tu branding. Incluye diseño
              responsive para PC y móvil y aplicación estratégica de UX/UI y
              branding.
            </p>
          </div>
          <div className="starter-foco">
            <p className="frase-final">Ahorra y obtene una pagina web unica</p>
            <div className="foquito-contenedor">
              <img src={foco} alt="" />
              <p className="text-2">950 €</p>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-mantenimiento">
        <div className="mantenimiento-contenedor">
          <div className="mantenimiento-textos">
            <p className="text-1">Mantenimiento Empresarial</p>
            <p className="text-2">pack</p>
            <p className="text-3">Cupos limitados</p>
            <p className="text-4">
              Adaptamos nuestros servicios de mantenimiento a las necesidades
              específicas de tu empresa para asegurar que tu presencia web
              funcione de manera óptima y segura.
            </p>
          </div>
        </div>
        <div className="boton">
          <a href="https://wa.link/cmn2hs" target="_blank">
            <p>cotiza tu proyecto</p>
          </a>
        </div>
      </div>
      <div className="landing-clientes">
        <div className="texto">
          <p className="text-1">Nuestros</p>
          <p className="text-2">clientes</p>
        </div>
        <div className="contenedor-de-la-tarjeta">
          <div className="tarjeta">
            <div className="div-img-contenedor">
              <ImagenCambiaEnHover
                currentImage={currentCard}
                className={"div-img"}
                isFirstRender={isFirstRender}
              >
                <div
                  className="div-img"
                  style={{ backgroundImage: `url(${FotoCliente1})` }}
                />
                <div
                  className="div-img"
                  style={{ backgroundImage: `url(${FotoCliente2})` }}
                />
                <div
                  className="div-img"
                  style={{ backgroundImage: `url(${FotoCliente3})` }}
                />
              </ImagenCambiaEnHover>
            </div>
            <div className="div-text">
              <img className="carita" src={carita} alt="" />
              <div className="manitos">
                <img className="like" src={like} alt="" />
                <img className="dislike" src={dislike} alt="" />
              </div>
              <p className="text-1" ref={textRef}>
                {texts[currentCard]}
              </p>
              <div className="flechas">
                <button
                  className="flecha-derecha"
                  onClick={() => handleCardChange(getNextIndex(currentCard))}
                >
                  <img src={flechacirculo} alt="" />
                </button>
                <button
                  className="flecha-izquierda"
                  onClick={() =>
                    handleCardChange(getPreviousIndex(currentCard))
                  }
                >
                  <img src={flechacirculo} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pregunas-frecuentes">
        <div className="text-img">
          <p className="text-1">Preguntas</p>
          <p className="text-2">Frecuentes</p>
          <div className="video-contenedor">
            <video src={video2} autoPlay muted loop></video>
          </div>
        </div>
        <div className="preguntas-contenedor">
          <FAQItem
            question="¿Cuánto tiempo toma desarrollar una página web?"
            answer="El tiempo de desarrollo depende de la
             complejidad del proyecto. En promedio, un sitio web básico
              puede tardar entre 5 y 7 semanas."
          />

          <FAQItem
            question="¿Qué incluye el mantenimiento de webs?"
            answer="Nuestro servicio de mantenimiento incluye actualizaciones de seguridad, respaldo de datos, y optimización del rendimiento del sitio."
          />
          <FAQItem
            question="¿Puedo personalizar mi pack de servicios?"
            answer="Sí, todos nuestros packs pueden ser personalizados para satisfacer las necesidades específicas de tu proyecto."
          />
        </div>
      </div>
      <div className="final">
        <div className="gmail">
          <a href="mailto:nodedigitalstudio@gmail.com">
            <p>nodedigitalstudio@gmail.com</p>
          </a>
        </div>
        <div className="node-contenedor">
          <div className="div-img">
            <DelayLink to="/">
              <img src={node} alt="" />
            </DelayLink>
            <p className="text">
              Esperemos que podamos trabajar juntos, tus proyectos e ideas nos
              motivan a seguir creando y afrontar esa barrera para poder seguir
              creando, contactanos y encendamos ideas juntos!{" "}
            </p>
          </div>
          <div className="div-derecha">
            <div className="servicios">
              <p className="text-1">Sobre nosotros</p>
              <p className="text-2">Servicios</p>
              <p className="text-3">Proyectos</p>
              <p className="text-4">Planes</p>
            </div>
            <div className="cualidades">
              <div className="izquierda">
                <p className="text-1">E-commerce</p>
                <p className="text-2">Mantenimiento</p>
                <p className="text-3">Diseño Grafico</p>
                <p className="text-4">Diseño ux/ui</p>
                <p className="text-5">Brand Strategy</p>
              </div>
              <div className="derecha">
                <p className="text-1">Fulltex</p>
                <p className="text-2">Baire House</p>
                <p className="text-3">Boscova</p>
                <p className="text-4">Ana Belén Tome</p>
              </div>
              <div className="planes">
                <p className="text-1">Basic</p>
                <p className="text-2">PlanPro</p>
                <p className="text-3">Business</p>
                <p className="text-4">Tu Proyecto</p>
              </div>
            </div>
          </div>
        </div>
        <div className="derechos-reservados">
          <p>2024 Node Digital Studio ®</p>
          <div className="redes">
            <a href="https://www.linkedin.com/company/node-digital-studio">
              <div>
                <img src={lin} alt="" />
              </div>
            </a>
            <a href="https://www.facebook.com/Nodedigitalstudio">
              <div>
                <img src={fb} alt="" />
              </div>
            </a>
            <a href="https://www.instagram.com/nodedigitalstudio/">
              <div>
                <img src={ig} alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        closeOnClick={true}
        theme="dark"
      />
      <Snowfall
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
        radius={[10.0, 50.0]}
        snowflakeCount={5}
        wind={[-2.5, 5.0]}
        rotationSpeed={[0.0, 0.1]}
        images={images}
      />
    </div>
  );
};
export default Landing;
