import React, { useState } from "react";
import "./contacto.css";
import { Button } from "../../componentes/Button/Button";
import Carita from "../../svg/carita.svg";
import Foco from "../../svg/foco prendido.svg";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function Contacto() {
  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      comoNosConocio: "",
      desarrolloWeb: false,
      detalles: "",
      email: "",
      mensaje: "",
      nombreCompleto: "",
      nombreEmpresa: "",
      planBasico: false,
      planBusiness: false,
      ubicacion: "",
      redesSociales: "",
      brandStrategy: false,
      planPro: false,
      otros: false,
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(
        "https://gin2o0p902.execute-api.sa-east-1.amazonaws.com/contactanos",
        data
      )
      .then(() => {
        toast.success("Formulario enviado");
        reset();
      })
      .catch(() => {
        toast.error("Error al enviar el formulario");
      });
  };

  const [checkedBoxes, setCheckedBoxes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleCheckboxChange = (event, name, id) => {
    setValue(name, event.target.checked);
    const { checked } = event.target;
    if (checked) {
      let newCheckedBoxes = [...checkedBoxes];
      newCheckedBoxes[id] = true;
      setCheckedBoxes(newCheckedBoxes);
    } else {
      let newCheckedBoxes = [...checkedBoxes];
      newCheckedBoxes[id] = false;
      setCheckedBoxes(newCheckedBoxes);
    }
  };

  return (
    <div className="class-contenedor">
      <div className="contacto-home-contenedor">
        <div className="contacto-background-gradient" />
        <h1>TRABAJEMOS JUNTOS!</h1>
      </div>
      <div className="tarjeta-contacto-contenedor">
        <div className="contacto-tarjeta-borde">
          <div className="contacto-tarjeta-titulo">
            <h1>¿Listo para llevar tus proyectos al siguiente nivel?</h1>
          </div>
          <div className="contacto-tarjeta-text">
            <div className="contacto-tarjeta-texto-p">
              <p>
                En Node, valoramos la creatividad, el compromiso y la excelencia
                en todo lo que hacemos. Si estás buscando un equipo apasionado y
                altamente competente para llevar a cabo tu próximo proyecto, has
                llegado al lugar adecuado.
              </p>
              <p style={{ color: "rgba(235, 85, 0, 1)" }}>
                Si deseas hablar con nosotros antes de completar el formulario,
                para guiarte en el proceso, siéntete libre de contactarnos por
                email.
              </p>
              <p>
                Nos enorgullece ofrecer soluciones creativas y personalizadas
                que se ajusten a tus necesidades. Si estás interesado en
                trabajar con nosotros, también puedes completar el formulario a
                continuación.
              </p>
              <p> ¡Esperamos colaborar contigo!</p>
            </div>
            <div className="contacto-tarjeta-redes">
              <div className="foco-img-text">
                <div className="foco-contenedor">
                  {" "}
                  <img src={Foco} alt="" />
                </div>

                <p>Encendamos Ideas</p>
              </div>

              <div className="svg-contenedor">
                <div className="svg-redes">
                  <Link
                    to="https://www.facebook.com/Nodedigitalstudio?locale=es_LA"
                    target="_blank"
                  >
                    <FaFacebookSquare />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/node-digital-studio/"
                    target="_blank"
                  >
                    {" "}
                    <FaLinkedin />{" "}
                  </Link>
                  <Link
                    to="https://www.instagram.com/nodedigitalstudio/"
                    target="_blank"
                  >
                    {" "}
                    <FaInstagram />
                  </Link>
                </div>
                <div className="svg-text">
                  <p>Node Digital Studio</p>
                </div>
              </div>

              <p>nodedigitalstudio@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="formulario-contenedor">
        <div className="text-input">
          <p>
            ¿Cuál es tu nombre completo? <span>*</span>
          </p>
          <input
            {...register("nombreCompleto", {
              required: true,
              minLength: 3,
              maxLength: 100,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            type="text"
          />
        </div>
        <div className="text-input">
          <p>
            ¿En qué país y ciudad te encontrás? <span>*</span>
          </p>
          <input
            {...register("ubicacion", {
              required: true,
              minLength: 3,
              maxLength: 100,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            type="text"
          />
        </div>
        <div className="text-input">
          <p>
            ¿Cuál es el nombre de tu empresa? <span>*</span>
          </p>
          <input
            {...register("nombreEmpresa", {
              required: true,
              minLength: 3,
              maxLength: 100,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            type="text"
          />
        </div>
        <div className="text-input">
          <p>
            ¿Cuál es tu email? <span>*</span>
          </p>
          <input
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            type="text"
          />
        </div>
        <div className="text-checkbox-contenedor">
          <h1>LOS SERVICIOS QUE BRINDAMOS</h1>
          <div className="checkbox-contenedor">
            <label className={checkedBoxes[0] ? "active" : ""}>
              {" "}
              <input
                type="checkbox"
                {...register("desarrolloWeb")}
                onChange={(e) => handleCheckboxChange(e, "desarrolloWeb", 0)}
              />
              <p>+ DESARROLLO WEB</p>
            </label>
            <label className={checkedBoxes[1] ? "active" : ""}>
              {" "}
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "brandStategi", 1)}
              />
              <p>+ BRAND ESTRATEGY</p>
            </label>
            <label className={checkedBoxes[2] ? "active" : ""}>
              {" "}
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "planBasico", 2)}
              />
              <p>+ PLAN BASICO</p>
            </label>
            <label className={checkedBoxes[3] ? "active" : ""}>
              {" "}
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "planPro", 3)}
              />
              <p>+ PLAN PRO</p>
            </label>
            <label className={checkedBoxes[4] ? "active" : ""}>
              {" "}
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "planBusiness", 4)}
              />
              <p>+ PLAN BUSINESS</p>
            </label>
            <label className={checkedBoxes[5] ? "active" : ""}>
              {" "}
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "otros", 5)}
              />
              <p>+ OTROS</p>
            </label>
          </div>
        </div>
        <div className="formulario-solo-centro">
          <p>
            ¿Podrías darnos más detalles sobre lo que necesitas? <span>*</span>
          </p>
          <input {...register("detalles")} type="text" />
        </div>
        <div className="text-input">
          <p>
            Redes sociales / tu web <span>*</span>
          </p>
          <input {...register("redesSociales")} type="text" />
        </div>
        <div className="text-input">
          <p>¿Cómo nos conociste?</p>
          <input {...register("comoNosConocio")} type="text" />
        </div>
        <div className="mensaje-contenedor">
          <h1>¿Tienes algún mensaje o consulta adicional?</h1>
          <textarea
            {...register("mensaje")}
            name="mensaje"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div className="contactanos-button">
          <p>¡Gracias por tu interés en nuestros servicios!</p>
          <Button
            width="65rem"
            height="5rem"
            bgColor={"black"}
            bgHoverColor={"white"}
            containerClassname="proyectosButton"
          >
            <button className="contactoButton" type="submit">
              CONTACTA CON NOSOTROS
            </button>
          </Button>
        </div>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        theme="dark"
      />
    </div>
  );
}
