import React from "react";
import "./baires.css";
import Rayita from "../../svg/rayitas-chotas.svg";
import Rayanaranja from "../../svg/rayitas-chotas-naranjas.svg";
import Guitarras from "../../img/guitarras-baires.png";
import Catalogoguitar from "../../img/guitarras-catalogo-baires.png";
import Pianos from "../../img/pianos-catalogos-baires.png";
import mujer from "../../img/mujer-y-guitarra-baires.png";
import hombre from "../../img/hombre-guitarra-baires.png";
import BairesVideo from "../../videos/BairesVideo.mp4";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";
import Next from "../../componentes/siguiente/next";

export default function baires() {
  return (
    <>
      <div className="baires-main">
        <div className="baires-contenedor">
          <div className="baires-titulo">
            <h5>
              BAIRES
              <br /> HOUSE OF
              <br />
              <span>MUSIC</span>{" "}
            </h5>
            <div className="baires-rayita">
              <img src={Rayita} alt="" />
            </div>
          </div>
          <div className="baires-text">
            <p>
              Baires House of Music es un proyecto desarrollado durante mi
              formación en la carrera de Front End en Coderhouse. Nos propusimos
              crear un e-commerce especializado en instrumentos musicales, con
              un enfoque en la funcionalidad y la usabilidad.{" "}
              <span style={{ color: "#EB5500" }}>
                Nuestro objetivo principal fue diseñar una plataforma que fuera
                altamente funcional y responsiva,
              </span>{" "}
              integrando las últimas tendencias del mercado para ofrecer una
              experiencia de usuario óptima.
            </p>
          </div>
        </div>
        <div className="baires-video-contenedor">
          <video
            autoplay="autoplay"
            loop="loop"
            id="video_background"
            preload="auto"
            volume="0"
            className="baires-video"
          >
            <source src={BairesVideo} type="video/mp4" />
          </video>
          <div className="baires-background-gradient" />
          <div className="hide-on-mobile">
            <div className="baires-video-titulo">
              <h1>BAIRES</h1>
            </div>
            <div className="baires-video-text">
              <p>
                Baires es un caso de estudio que hicimos en grupo para poder
                programar en react
              </p>
              <p>es un E-commerce dedicado a los instrumentos musicales</p>
            </div>
          </div>
          <div className="hide-on-desktop">
            <div className="baires-contenedor">
              <div className="baires-titulo">
                <h5>
                  BAIRES
                  <br /> HOUSE OF
                  <br />
                  <span>MUSIC</span>{" "}
                </h5>
              </div>
              <div className="baires-text">
                <p>
                  Baires House of Music es un proyecto desarrollado durante mi
                  formación en la carrera de Front End en Coderhouse. Nos
                  propusimos crear un e-commerce especializado en instrumentos
                  musicales, con un enfoque en la funcionalidad y la usabilidad.{" "}
                  <span style={{ color: "#EB5500" }}>
                    Nuestro objetivo principal fue diseñar una plataforma que
                    fuera altamente funcional y responsiva,
                  </span>{" "}
                  integrando las últimas tendencias del mercado para ofrecer una
                  experiencia de usuario óptima.
                </p>
              </div>
              <div className="baires-rayita">
                <p>//</p>
              </div>
            </div>
          </div>
        </div>
        <div className="baires-texto">
          <p>
            Centrado en la programación y la usabilidad de la web responsive
          </p>
        </div>
        <div className="baires-guitarras-contenedor">
          <div className="baires-guitarras">
            <AppearTransitions direction="izquierda">
              <img src={Guitarras} alt="" />
            </AppearTransitions>
          </div>
          <AppearTransitions
            direction="derecha"
            className="baires-guitarras-text"
          >
            <p>
              Bienvenido a{" "}
              <span style={{ color: "#EB5500" }}>Baires House of Music</span>{" "}
              eCommerce, donde la pasión por la música se encuentra con la
              excelencia en cada interacción.
            </p>
            <p>
              Nuestra plataforma combina la experiencia exclusiva de comprar
              on-line con funcionalidades avanzadas. Explora nuestro catálogo de
              instrumentos musicales y comunícate directamente con nuestros
              expertos a través de nuestro chat integrado.
            </p>
            <p>
              Además, simplificamos tu proceso de compra con nuestro carrito de
              compras integrado, brindándote una experiencia de compra
              profesional y sofisticada. Descubre una nueva forma de comprar en
              línea con Baires House of Music, donde la eficiencia y la
              excelencia se encuentran en cada paso del camino.
            </p>
          </AppearTransitions>
        </div>
        <AppearTransitions
          direction="derecha"
          className="baires-sobre-el-proyecto-rayita"
        >
          <img src={Rayanaranja} alt="" />
          <h1>
            SOBRE EL
            <br /> PROYECTO
          </h1>
        </AppearTransitions>
        <AppearTransitions
          direction="izquierda"
          className="baires-sobre-el-proyecto"
        >
          <h1>
            SOBRE <br /> EL PROYECTO
          </h1>
          <p>
            Exploramos una variedad de enfoques y conceptos antes de decidirnos
            por desarrollar un e-commerce con un carrito integrado. Aunque
            consideramos opciones como una tienda de ropa en línea o la venta de
            zapatillas exclusivas, nos decidimos por una casa de música, una
            pasión compartida por todo el equipo.
            <br />
            Al comenzar este proyecto, nos enfrentamos a varios desafíos ya que
            nos adentrábamos en un terreno desconocido y nos sentíamos abrumados
            por la cantidad de herramientas nuevas. Sin embargo, superamos esta
            barrera con determinación, adquiriendo nuevos conocimientos y
            demostrando nuestra disposición para trabajar arduamente.
          </p>
        </AppearTransitions>
        <div className="baires-galeria">
          <img src={Catalogoguitar} alt="" className="guitarra" />
          <img src={Pianos} alt="" className="pianos" />
          <img src={mujer} alt="" className="mujer" />
          <img src={hombre} alt="" className="hombre" />
          <div className="textos">
            <div className="centered">
              <h1>MUSICA</h1>
              <p>
                Nos comprometimos por completo con nuestra idea, enfocándonos en
                nuestra pasión por los instrumentos musicales. Este proyecto nos
                entusiasmó profundamente, ya que dedicamos todo nuestro tiempo y
                compromiso a él. Nos inspiramos en la música que escuchamos a
                diario, incluyendo bandas legendarias como Pink Floyd, Queen,
                Jimi Hendrix, Eric Clapton y Jimmy Page y The Beatles, entre
                otras.
              </p>
              <p>
                Nuestra propuesta de diseño de eCommerce para Baires House of
                Music, aunque ficticia, resultó en mejoras significativas en la
                venta de instrumentos musicales.
              </p>
              <p>
                A través de pruebas e iteraciones, introdujimos una plataforma
                online intuitiva y atractiva que ampliaría la base de clientes y
                aumentaría las ventas. La facilidad de navegación y herramientas
                como el carrito de compras mejoraron la experiencia del usuario
                y fortalecieron la relación con los clientes.
              </p>
              <p style={{ color: "#EB5500" }}>
                Estas propuestas de mejoras destacaron el potencial real de
                nuestro diseño para optimizar el negocio de Baires House of
                Music en el mercado de instrumentos musicales.
              </p>
            </div>
          </div>
        </div>

        <Next siguiente="/ia-proyectos" />
      </div>
    </>
  );
}
