import React, { useRef, useState } from "react";
import "./mantenimiento.css";
import FAQItem from "../../componentes/faqitem/faqitem";
import Focowhite from "../../img/landing/Capa_1.svg";
import Video1 from "../../videos/mantenimiento/videos/cuadrado.mp4";
import Video2 from "../../videos/mantenimiento/videos/exagono.mp4";
import Firmanode from "../../videos/mantenimiento/SVGs/Node Digital Studio.svg";
import Video3 from "../../videos/mantenimiento/videos/landing.mp4";
import Video4 from "../../videos/mantenimiento/videos/pc.mp4";
import Fulltexlogo from "../../videos/mantenimiento/fulltex.png";
import Node from "../../svg/Node Digital Studio.svg";
import video2 from "../../img/landing/videos/ContenedorVideo.mp4";
import Proyectos from "../../svg/Ver Proyecto.svg";
import Dona from "../../videos/mantenimiento/videos/donita.mp4";
import fb from "../../img/landing/Frame 553.svg";
import lin from "../../img/landing/Vector.svg";
import ig from "../../img/landing/Frame 555.svg";
import node from "../../img/landing/LogoSvg.svg";
import DelayLink from "../../componentes/SwitchTransitions/DelayLink";
import Video5 from "../../videos/mantenimiento/videos/marcianito.mp4";
import check from "../../svg/checkPoint.svg";
import like from "../../img/landing/mdi_like.svg";
import carita from "../../img/landing/CaritaSatanica.svg";
import dislike from "../../img/landing/mdi_dislike.svg";
import flechacirculo from "../../img/landing/Group 630.svg";
import FotoCliente1 from "../../img/landing/FotoFulltex.png";
import FotoCliente2 from "../../img/landing/FotoBoscova.png";
import FotoCliente3 from "../../img/landing/FotoCliente3.png";
import { ImagenCambiaEnHover } from "../../componentes/imagenCambiaEnHover/ImagenCambiaEnHover";
import { useLenis } from "@studio-freight/react-lenis";
import { useGSAP } from "@gsap/react";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import { useForm } from "react-hook-form";
import Condicional from "../../utils/Condicional";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
export const Mantenimiento = () => {
  gsap.registerPlugin(useGSAP);
  const [currentCard, setCurrentCard] = useState(0);
  const [currentCardText, setCurrentCardText] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeCard, setActiveCard] = useState(0);
  const textRef = useRef(null);
  const navMenuRef = useRef(null);
  const texts = [
    "Trabajar con Node Digital Studio es una experiencia fantástica. Entendieron nuestra visión desde el primer momento y la convirtieron en una identidad visual que realmente somos. Su enfoque en la calidad y la innovación ha fortalecido nuestra posición en el mercado. ¡Los recomendamos totalmente! - Carlos y Leo de Fulltex",
    "Node Digital Studio ha sido un gran aliado en la creación de nuestra marca. Nos ayudaron con todo, desde el nombre y el logo hasta nuestra presencia digital. Su equipo es creativo, profesional y muy colaborativo. Gracias a ellos, nuestra marca refleja  nuestros valores. - jorge de Boscova ",
    "El equipo de Node Digital Studio diseñó una website que realmente captura la esencia de mi trabajo en terapias y retiros. Su atención al detalle y compromiso con la personalización resultaron en una plataforma funcional que comunica mi enfoque de ayudar a las personas a vivir una vida plena y equilibrada. - Ana Belén Tomé ",
  ];

  const textosTarjetas = [
    {
      titulo: "FULLTEX",
      mensaje:
        "FullTex, fundado en 2023, se ha consolidado como un líder en revestimientos plásticos texturizados y ha fortalecido su posición en el mercado con una identidad visual inspirada en la Escuela Bauhaus, reflejando su compromiso con la calidad y la innovación.",
      link: "/fulltex",
    },
    {
      titulo: "BAIRES HOUSE OF MUSIC",
      mensaje:
        "Baires House of Music, fue creado para un programa de estudios de Front end. Se implemento todas las categorías y herramientas esenciales de un e-commerce, fue el proyecto que hace unos años unio al equipo de Node",
      link: "/baires",
    },
    {
      titulo: "BOSCOVA",
      mensaje:
        "Boscova Beer, inicialmente un proyecto educativo, se convirtió en un destacado cliente para nuestro estudio, donde desde el nombre y logo hasta la presencia web, colaboramos estrechamente para asegurar que cada aspecto de la marca reflejara sus valores y visión.",
      link: "/boscova",
    },
    {
      titulo: "ANA BELÉN TOME",
      mensaje:
        "La website de Ana Belén Tomé, especialista en terapias holísticas en España, está diseñada para reflejar su enfoque en ayudar a las personas a vivir una vida plena y equilibrada.",
      link: "#",
    },
  ];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    reset,
    formState,
  } = useForm({
    defaultValues: {
      nombre: "",
      empresa: "",
      pais: "",
      email: "",
      mensaje: "",
      origen: "Mantenimiento",
    },
  });
  const changeActiveCard = (index) => {
    gsap.fromTo(
      ".appear-transition",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.9,
      }
    );
    setActiveCard(index);
  };

  const handleCardChange = (index) => {
    setCurrentCard(index);
    setIsFirstRender(false);
    gsap
      .timeline()
      .to(textRef.current, {
        duration: 0.5,
        filter: "blur(10px)",
        onComplete: () => {
          setCurrentCardText(index);
        },
      })
      .to(textRef.current, {
        duration: 0.5,
        filter: "blur(0px)",
      });
  };

  useGSAP(() => {
    gsap.set(navMenuRef.current, { scaleY: 0 });
  }, []);

  useGSAP(() => {
    if (!isMenuOpen) {
      gsap.to(navMenuRef.current, {
        duration: 0.5,
        scaleY: 0,
        ease: "power1.inOut",
        transformOrigin: "top",
      });
    } else {
      gsap.to(navMenuRef.current, {
        duration: 0.5,
        scaleY: 1,
        ease: "power1.inOut",
        transformOrigin: "top",
      });
    }
  }, [isMenuOpen]);

  const getNextIndex = (index) => {
    if (index === 2) {
      return 0;
    } else {
      return index + 1;
    }
  };

  const getPreviousIndex = (index) => {
    if (index === 0) {
      return 2;
    } else {
      return index - 1;
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(
        "https://gin2o0p902.execute-api.sa-east-1.amazonaws.com/landing_node_mail",
        data
      )
      .then(() => {
        toast.success("Formulario enviado");
        reset();
      })
      .catch(() => {
        toast.error("Error al enviar el formulario");
      });
  };

  const onHandleError = (data) => {
    console.log(data);
    toast.error("Error al enviar el formulario");
  };

  return (
    <div className="mantenimiento-landing-main">
      <div className="intro">
        <video src={Video3} autoPlay loop muted></video>
        <div className="soluciones-responsive">
          <p className="texto-1">
            ¡SOLUCIONES <br />
            <span>INTEGRALES!</span>
          </p>
          <p className="texto-2">Mantenimiento de Software y Páginas Web</p>
          <p className="texto-3">
            Garantiza el buen funcionamiento de tu plataforma digital y protege
            tu inversión con nuestros servicios de mantenimiento personalizados
            y continuos.
          </p>
          <a href="https://wa.link/3f15un" target="_blank">
            <div className="boton">
              <p>contactanos</p>
              <img src={Focowhite} alt="" />
            </div>
          </a>
        </div>
        <div className="mascara-responsive" />
      </div>
      <div className="soluciones-main">
        <div className="soluciones-textos">
          <p className="texto-1">
            ¡SOLUCIONES <br />
            <span>INTEGRALES!</span>
          </p>
          <p className="texto-2">Mantenimiento de Software y Páginas Web</p>
          <p className="texto-3">
            Garantiza el buen funcionamiento de tu plataforma digital y protege
            tu inversión con nuestros servicios de mantenimiento personalizados
            y continuos.
          </p>
          <a href="https://wa.link/3f15un" target="_blank">
            <div className="boton">
              <p>contactanos</p>
              <img src={Focowhite} alt="" />
            </div>
          </a>
        </div>
        <div className="video-soluciones-contenedor">
          <video src={Video1} autoPlay loop muted></video>
        </div>
        <div className="soluciones-responsive">
          <p className="texto-1">
            ¿Por qué es importante el <span>mantenimiento</span>?
          </p>
          <p className="texto-2">
            El mantenimiento regular de tu sitio web o software es clave para
            mejorar la seguridad, asegurar un rendimiento óptimo y ofrecer una
            experiencia de usuario impecable. Evita problemas técnicos, caídas
            del servicio o vulnerabilidades de seguridad con nuestras soluciones
            especializadas.
          </p>
          <img src={Node} alt="" />
        </div>
      </div>
      <div className="importancia-mantenimiento">
        <div className="mascara-blanca" />

        <div className="arriba-texto">
          <p className="texto-1"> ¿Por qué es importante</p>
          <p className="texto-2">
            el <span>mantenimiento?</span>
          </p>
        </div>
        <div className="abajo-texto">
          <p>
            El mantenimiento regular de tu sitio web o software es clave para
            mejorar la seguridad, asegurar un rendimiento óptimo y ofrecer una
            experiencia de usuario impecable. Evita problemas técnicos, caídas
            del servicio o vulnerabilidades de seguridad con nuestras soluciones
            especializadas.
          </p>
        </div>
        <video src={Video2} autoPlay loop muted></video>
        <img src={Firmanode} alt="" />
      </div>
      <div className="ventajas-contenedor">
        <div className="arriba-texto">
          <p className="texto-1">¡Ventajas de nuestro mantenimiento!</p>
          <p className="texto-2">
            Mayor seguridad frente a ciberataques y fallos, mejor rendimiento y
            velocidad de carga, experiencia de usuario optimizada, solución
            rápida de errores técnicos.
          </p>
        </div>
        <div className="abajo-video">
          <video src={Video4} autoPlay loop muted></video>
        </div>
        <div className="mascara-blanca" />
      </div>
      <div className="servicios-mantenimiento-main">
        <div className="arriba-texto">
          <p className="texto-1">Servicios</p>
          <p className="texto-2">
            Actualizaciones automáticas de software y sistemas, copias de
            seguridad y restauración en caso de fallos, monitorización continua
            del rendimiento, optimización técnica <span>SEO</span> para mejorar
            tu visibilidad online, soporte técnico especializado y resolución de
            incidencias
          </p>
        </div>
        <div className="landing-mantenimiento">
          <div className="mantenimiento-contenedor">
            <div className="mantenimiento-textos">
              <p className="text-1">Mantenimiento Empresarial</p>
              <p className="text-2">pack</p>
              <p className="text-3">Cupos limitados</p>
              <p className="text-4">
                Adaptamos nuestros servicios de mantenimiento a las necesidades
                específicas de tu empresa para asegurar que tu presencia web
                funcione de manera óptima y segura.
              </p>
            </div>
          </div>
          <div className="boton">
            <a href="https://wa.link/cmn2hs" target="_blank">
              <p>cotiza tu proyecto</p>
            </a>
          </div>
        </div>
      </div>
      <div className="fulltex-mantenimiento-main">
        <div className="izquierda-texto">
          <p className="texto-1">
            "Llegamos con una necesidad muy urgente, ya que nuestra fabrica
            necesitaba de un orden y de poder tener mas tiempo en otros aspectos
            del trabajo y que se automatice de manera que los tiempos de
            producción sean mas eficientes"
          </p>
          <p className="texto-2">
            -Leonardo Pacheco CEO <br /> <span>Fulltex revestimientos </span>
          </p>
          <a href="https://www.fulltex.com.ar/" target="_blank">
            <img src={Fulltexlogo} alt="" />
          </a>
        </div>
        <div className="derecha-imagen" />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onHandleError)}
        className="mantenimiento-formulario"
      >
        <div className="formulario-borde">
          <div className="arriba">
            <div className="izquierda">
              <p className="texto-1">
                ENCENDAMOS <span>IDEAS</span>
              </p>
              <p className="texto-2">
                Solicita una auditoría gratuita y descubre cómo optimizar tu
                sitio o software
              </p>
            </div>
            <div className="derecha">
              <p className="texto-1">
                Comunícate con nosotros de forma directa para que podamos
                ayudarte en la optimización y renovación de tu empresa o negocio
              </p>
              <p className="texto-2">nodedigitalstudio@gmail.com</p>
            </div>
          </div>
          <div className="medio-arriba">
            <div className="izquierda">
              <div className="arriba-nombre">
                <p className="texto-1">¿Cuál es tu nombre completo? </p>
                <p className="texto-2">
                  Requerido<span>*</span>
                </p>
              </div>
              <input
                type="text"
                {...register("nombre", {
                  required: true,
                  pattern: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/,
                })}
              />
            </div>
            <div className="derecha">
              <div className="arriba-nombre">
                <p className="texto-1">
                  ¿Cuál es el nombre de tu empresa o negocio?
                </p>
                <p className="texto-2">(opcional)</p>
              </div>
              <input
                type="text"
                {...register("empresa", {
                  required: false,
                  pattern: /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/,
                })}
              />
            </div>
          </div>
          <div className="medio-abajo">
            <div className="izquierda">
              <div className="arriba-nombre">
                <p className="texto-1">¿tu mail o mail de la empresa? </p>
                <p className="texto-2">
                  Requerido<span>*</span>
                </p>
              </div>
              <input
                type="email"
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </div>
            <div className="derecha">
              <div className="arriba-nombre">
                <p className="texto-1">¿En que país estas? </p>
                <p className="texto-2">
                  Requerido<span>*</span>
                </p>
              </div>
              <input
                type="text"
                {...register("pais", {
                  required: true,
                  pattern: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/,
                })}
              />
            </div>
          </div>
          <div className="abajo">
            <p className="texto-1">Tu mensaje</p>
            <textarea
              {...register("mensaje", { required: true })}
              name="mensaje..."
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </div>
        <div className="botones-checkpoints">
          <div className="izquierda">
            <div className="captcha-1">
              <button type="checkbox"></button>
            </div>
            <p>SOY HUMANO (CAPTCHA)</p>
          </div>
          <div className="derecha">
            <div className="arriba">
              <div className="check-1">
                <button disabled>Tu Nombre</button>
                <Condicional test={getFieldState("nombre", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-2">
                <button disabled>Nombre de tu empresa</button>
                <Condicional test={getFieldState("empresa", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-3">
                <button disabled>Mail</button>
                <Condicional test={getFieldState("email", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-4">
                <button disabled>País</button>
                <Condicional test={getFieldState("pais", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
              <div className="check-5">
                <button disabled>Tu Mensaje</button>
                <Condicional test={getFieldState("mensaje", formState).isDirty}>
                  <img src={check} alt="" />
                </Condicional>
              </div>
            </div>
            <div className="abajo">
              <div className="contenedor-boton">
                <button type="submit">ENVIAR</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="pregunas-frecuentes">
        <div className="text-img">
          <p className="text-1">Preguntas</p>
          <p className="text-2">Frecuentes</p>
          <div className="video-contenedor">
            <video src={video2} autoPlay muted loop></video>
          </div>
        </div>
        <div className="preguntas-contenedor">
          <FAQItem
            question="¿Cada cuánto tiempo es necesario realizar el mantenimiento?"
            answer="Se recomienda realizarlo de forma mensual, aunque dependerá de la complejidad de la plataforma."
          />

          <FAQItem
            question="¿Y si ya tengo problemas técnicos?"
            answer="Ofrecemos un diagnóstico inicial para resolver los problemas antes de empezar con el mantenimiento regular."
          />
          <FAQItem
            question="¿Qué incluye una auditoría de mantenimiento?"
            answer="La auditoría de mantenimiento incluye un análisis exhaustivo de tu sitio web o software para identificar problemas técnicos, vulnerabilidades de seguridad, y áreas de mejora en el rendimiento"
          />
        </div>
      </div>
      <div className="proyectos-contenedor">
        <div className="titulo">
          <p className="texto-1">PROYECTOS</p>
          <img src={Node} alt="" />
        </div>
        <div className="appear-transition">
          <div className="tarjeta-texto">
            {/*éste texto es el que tiene que cambiar*/}
            <p className="texto-1">{textosTarjetas[activeCard].titulo}</p>
            <p className="texto-2">{textosTarjetas[activeCard].mensaje}</p>
          </div>
          <div className="ver-proyecto-contenedor">
            <DelayLink to={textosTarjetas[activeCard].link}>
              <img src={Proyectos} alt="" />
            </DelayLink>
          </div>
        </div>
        <div className="video-contenedor">
          <video src=""></video>
        </div>
        <div className="img-tarjetas">
          {/* anchor */}
          <div
            className="tarjetita img-1"
            onClick={() => changeActiveCard(0)}
          />
          <div
            className="tarjetita img-2"
            onClick={() => changeActiveCard(1)}
          />
          <div
            className="tarjetita img-3"
            onClick={() => changeActiveCard(2)}
          />
          <div
            className="tarjetita img-4"
            onClick={() => changeActiveCard(3)}
          />
        </div>
        <div className="video">
          <video src={Dona} autoPlay muted loop></video>
        </div>
      </div>
      <div className="proyectos-responsive">
        <p className="texto-1">
          <span className="nuemros">2024</span>
          <br />
          Nuestros clientes <br />
          <span className="letras">y Proyectos</span>
        </p>
        <img src={Node} alt="" />
        <div className="tarjetas-contenedor">
          <div className="tarjeta FT">
            <p className="texto-1">FULLTEX</p>
            <p className="texto-2">
              FullTex, fundado en 2023, se ha consolidado como un líder en
              revestimientos plásticos texturizados y ha fortalecido su posición
              en el mercado con una identidad visual inspirada en la Escuela
              Bauhaus, reflejando su compromiso con la calidad y la innovación.
            </p>
          </div>
          <div className="tarjeta BM">
            <p className="texto-1">BAIRES HOUSE OF MUSIC</p>
            <p className="texto-2">
              Baires House of Music, fue creado para un programa de estudios de
              Front end. Se implemento todas las categorías y herramientas
              esenciales de un e-commerce, fue el proyecto que hace unos años
              unio al equipo de Node
            </p>
          </div>
          <div className="tarjeta BVA">
            <p className="texto-1">BOSCOVA</p>
            <p className="texto-2">
              Boscova Beer, inicialmente un proyecto educativo, se convirtió en
              un destacado cliente para nuestro estudio, donde desde el nombre y
              logo hasta la presencia web, colaboramos estrechamente para
              asegurar que cada aspecto de la marca reflejara sus valores y
              visión.
            </p>
          </div>
          <div className="tarjeta ANA">
            <p className="texto-1">ANA BELÉN TOME </p>
            <p className="texto-2">
              La website de Ana Belén Tomé, especialista en terapias holísticas
              en España, está diseñada para reflejar su enfoque en ayudar a las
              personas a vivir una vida plena y equilibrada.
            </p>
          </div>
        </div>
        <div className="video-contenedor">
          <video src={Dona} autoPlay muted loop></video>
        </div>
      </div>
      <div className="landing-clientes">
        <div className="texto">
          <p className="text-1">Nuestros</p>
          <p className="text-2">clientes</p>
        </div>
        <div className="contenedor-de-la-tarjeta">
          <div className="tarjeta">
            <div className="div-img-contenedor">
              <ImagenCambiaEnHover
                currentImage={currentCard}
                className={"div-img"}
                isFirstRender={isFirstRender}
              >
                <div
                  className="div-img"
                  style={{ backgroundImage: `url(${FotoCliente1})` }}
                />
                <div
                  className="div-img"
                  style={{ backgroundImage: `url(${FotoCliente2})` }}
                />
                <div
                  className="div-img"
                  style={{ backgroundImage: `url(${FotoCliente3})` }}
                />
              </ImagenCambiaEnHover>
            </div>
            <div className="div-text">
              <img className="carita" src={carita} alt="" />
              <div className="manitos">
                <img className="like" src={like} alt="" />
                <img className="dislike" src={dislike} alt="" />
              </div>
              <p className="text-1" ref={textRef}>
                {texts[currentCard]}
              </p>
              <div className="flechas">
                <button
                  className="flecha-derecha"
                  onClick={() => handleCardChange(getNextIndex(currentCard))}
                >
                  <img src={flechacirculo} alt="" />
                </button>
                <button
                  className="flecha-izquierda"
                  onClick={() =>
                    handleCardChange(getPreviousIndex(currentCard))
                  }
                >
                  <img src={flechacirculo} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="final">
        <div className="gmail">
          <a href="mailto:nodedigitalstudio@gmail.com">
            <p>nodedigitalstudio@gmail.com</p>
          </a>
        </div>
        <div className="node-contenedor">
          <div className="div-img">
            <DelayLink to="/">
              <img src={node} alt="" />
            </DelayLink>
            <p className="text">
              Esperemos que podamos trabajar juntos, tus proyectos e ideas nos
              motivan a seguir creando y afrontar esa barrera para poder seguir
              creando, contactanos y encendamos ideas juntos!{" "}
            </p>
          </div>
          <div className="div-derecha">
            <div className="servicios">
              <p className="text-1">Sobre nosotros</p>
              <p className="text-2">Servicios</p>
              <p className="text-3">Proyectos</p>
              <p className="text-4">Planes</p>
            </div>
            <div className="cualidades">
              <div className="izquierda">
                <p className="text-1">E-commerce</p>
                <p className="text-2">Mantenimiento</p>
                <p className="text-3">Diseño Grafico</p>
                <p className="text-4">Diseño ux/ui</p>
                <p className="text-5">Brand Strategy</p>
              </div>
              <div className="derecha">
                <p className="text-1">Fulltex</p>
                <p className="text-2">Baire House</p>
                <p className="text-3">Boscova</p>
                <p className="text-4">Ana Belén Tome</p>
              </div>
              <div className="planes">
                <p className="text-1">Basic</p>
                <p className="text-2">PlanPro</p>
                <p className="text-3">Business</p>
                <p className="text-4">Tu Proyecto</p>
              </div>
            </div>
          </div>
        </div>
        <div className="derechos-reservados">
          <p>2024 Node Digital Studio ®</p>
          <div className="redes">
            <a href="https://www.linkedin.com/company/node-digital-studio">
              <div>
                <img src={lin} alt="" />
              </div>
            </a>
            <a href="https://www.facebook.com/Nodedigitalstudio">
              <div>
                <img src={fb} alt="" />
              </div>
            </a>
            <a href="https://www.instagram.com/nodedigitalstudio/">
              <div>
                <img src={ig} alt="" />
              </div>
            </a>
          </div>
        </div>
        <video src={Video5} autoPlay muted loop></video>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        theme="dark"
      />
    </div>
  );
};
export default Mantenimiento;
