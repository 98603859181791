import "./sobrenosotros.css";
import { useRef, useState } from "react";
import React from "react";
import Roles from "../../componentes/roles/roles";
import Tomas from "../../img/tomas.jpeg";
import Gs from "../../img/gs.jpg";
import Mati from "../../img/mati.jpeg";
import Agus from "../../img/agustina.jpeg";
import { FaAngleDown } from "react-icons/fa6";
import { Link } from "react-router-dom";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DelayLink from "../../componentes/SwitchTransitions/DelayLink";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const equipoData = [
  {
    textoIzquierda: "Since 2023",
    textoCentro: "TOMAS ROBLEDO",
    textoDerecha: "Developer",
    foto: Tomas,
  },
  {
    textoIzquierda: "Since 2023",
    textoCentro: "GABRIEL SOSA",
    textoDerecha: "Developer / Cofounder",
    foto: Gs,
  },
  {
    textoIzquierda: "Since 2024",
    textoCentro: "AGUSTINA OJEDA",
    textoDerecha: "Brand Strategy",
    foto: Agus,
  },
  {
    textoIzquierda: "Since 2023",
    textoCentro: "MATIAS ROSAS",
    textoDerecha: "Art Director / Founder",
    foto: Mati,
  },
];

export function SobreNosotros() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="sobre-nosotros-container">
      <div className="seccionBlanca">
        <div className="sobre-nosotros">
          <div className="nosotros-background-gradient" />
          <div className="nosotros-titulo">
            <h1>SOBRE NOSOTROS</h1>
            <p>
              En Node Digital Studio, estamos en una misión apasionante: <br />{" "}
              Fusionar creatividad y tecnología para ofrecer soluciones
              digitales innovadoras y atractivas. Somos un equipo de
              diseñadores, desarrolladores y estrategas apasionados que se
              esfuerzan por superar los límites de lo convencional.
            </p>
          </div>
        </div>
        <div className="nuestra-vision">
          <div className="nuestra-text">
            <h1>NUESTRA VISION</h1>
            <p>
              En Node Digital Studio, estamos en una misión apasionante: <br />{" "}
              fusionar creatividad y tecnología para ofrecer soluciones
              digitales innovadoras y atractivas. Somos un equipo de
              diseñadores, desarrolladores y estrategas apasionados que se
              esfuerzan por superar los límites de lo convencional.
            </p>
          </div>
          <div className="nuestra-text">
            <h1>NUESTRA PASION</h1>
            <p>
              En Node Digital Studio, abrazamos la colaboración y la innovación.
              Cada proyecto es una oportunidad para explorar nuevas ideas y
              desafiar lo establecido. Nos enorgullece nuestro enfoque centrado
              en el cliente, trabajando estrechamente con cada cliente para
              comprender sus necesidades y ofrecer soluciones personalizadas que
              superen sus expectativas.
            </p>
          </div>
        </div>
        <div className="comprendemos">
          <div className="comprendemos-text">
            <p>
              COMPRENDEMOS LAS NECESIDADES DE NUESTROS CLIENTES <br /> INDAGAMOS
              EN LO MÁS PROFUNDO PARA <br /> COMUNICAR LO QUE REALMENTE QUIEREN
            </p>
          </div>
        </div>
      </div>
      <div className="seccionNegra">
        <div className="equipo-contenedor">
          <div className="equipo-text">
            <h1>NUESTRO EQUIPO</h1>
            <h2>
              NUESTRO EQUIPO DE PROFESIONALES <br />
              QUE ACOMPAÑARÁN TUS PROYECTOS
            </h2>
          </div>
        </div>
        <div className="Roles">
          {equipoData.map((equipo) => (
            <Roles {...equipo} />
          ))}
        </div>
        <div className="contacto-final">
          <DelayLink to="/contacto">
            <h1>CONTACTANOS</h1>
          </DelayLink>
        </div>
      </div>
    </div>
  );
}
export default SobreNosotros;
