import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Fondo from "../../svg/indexLogo.svg";
import FondoNegro from "../../svg/indexLogoNegro.svg";

import { ReactComponent as Gmail } from "../../svg/gmail.svg";
import { ReactComponent as Direccion } from "../../svg/ubicacion.svg";
import { ReactComponent as Telefonos } from "../../svg/tel.svg";
import { ReactComponent as DarkGmail } from "../../svg/darkGmail.svg";
import { ReactComponent as DarkDireccion } from "../../svg/darkDireccion.svg";
import { ReactComponent as DarkTelefonos } from "../../svg/darkTelephone.svg";
import Carrusel from "../../../src/componentes/carrusel/carrusel";
import FocoDark from "../../svg/foco apagado.svg";
import FocoLight from "../../svg/foco prendido.svg";
import LightSwitchON from "../../img/lightSwitchON.svg";
import LightSwitchOFF from "../../img/lightSwitchOFF.svg";
import SunDoodle from "../../img/sunDoodle.svg";
import MoonDoodle from "../../img/moonDoodle.svg";
import cx from "classnames";
import "./home.css";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Button } from "../../componentes/Button/Button";
import { isMobile } from "react-device-detect";
import DelayLink from "../../componentes/SwitchTransitions/DelayLink";
import FulltexLogo from "../../img/colaboradores/fulltexLogo.png";
import MidittLogo from "../../img/colaboradores/midittLogo.png";
import DachboneLogo from "../../img/colaboradores/dachboneLogo.png";
import BoscovaLogo from "../../img/colaboradores/boscovaLogo.png";
import MunicipioSarmientoLogo from "../../img/colaboradores/municipioSarmientoLogo.svg";
import KeivLogo from "../../img/colaboradores/keivLogo.png";

const colaboradoresFirstRow = [
  {
    nombre: "fulltex",
    imagen: FulltexLogo,
  },
  {
    nombre: "fulltex",
    imagen: MidittLogo,
  },
  {
    nombre: "fulltex",
    imagen: DachboneLogo,
  },
  {
    nombre: "fulltex",
    imagen: BoscovaLogo,
  },
];

const colaboradoresSecondRow = [
  {
    nombre: "fulltex",
    imagen: MunicipioSarmientoLogo,
  },
  {
    nombre: "fulltex",
    imagen: KeivLogo,
  },
];

export default function Home({ isLightMode, setIsLightMode }) {
  const [hoveredLetters, setHoveredLetters] = useState([]);
  const [shouldFixLogo, setShouldFixLogo] = useState(false);
  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const cardsFirstRowRef = useRef(null);
  const cardsSecondRowRef = useRef(null);
  const container = useRef(false);
  const carruselContainer = useRef(null);
  const lenisRef = useRef(null);
  const focoContainerClassName = cx("focoContainer", {
    switch: isLightMode,
  });
  const imagenFondoClassName = cx("imagenFondo", {
    imagenFondoFixed: shouldFixLogo,
  });
  const lightSwitchONClassName = cx("lightSwitchON", {
    lightSwitchONActive: isLightMode,
  });
  const lightSwitchOFFClassName = cx("lightSwitchOFF", {
    lightSwitchOFFInactive: isLightMode,
  });

  const homeContainerClassName = cx("homeContainer", {
    containerLightMode: isLightMode,
  });
  const lightSwitchContainerClassName = cx("lightSwitchContainer", {
    borderLightMode: isLightMode,
  });
  const handleMouseEnter = (index) => {
    setHoveredLetters([...hoveredLetters, index]);
  };

  const handleMouseLeave = (index) => {
    setHoveredLetters(hoveredLetters.filter((i) => i !== index));
  };
  const text = "TRABAJA     CON     NOSOTROS";

  useGSAP(() => {
    if (isMobile) {
      return;
    }
    gsap.set(cardsFirstRowRef.current, { xPercent: -25 });
    gsap.set(cardsSecondRowRef.current, { xPercent: 12.5 });
    gsap.to(cardsFirstRowRef.current, {
      xPercent: 25,
      scrollTrigger: {
        trigger: cardsFirstRowRef.current,
        endTrigger: cardsSecondRowRef.current,
        start: "top bottom-=500",
        end: "bottom+=500 top",
        scrub: 1,
      },
    });
    gsap.to(cardsSecondRowRef.current, {
      xPercent: -12.5,
      scrollTrigger: {
        trigger: cardsFirstRowRef.current,
        start: "top bottom-=500",
        end: "bottom+=500 top",
        scrub: 1.5,
      },
    });
  }, []);

  return (
    <div className={homeContainerClassName} ref={container}>
      <div id="principal" className="fondo">
        {!isLightMode ? (
          <img src={Fondo} alt="" className="imagenFondo" />
        ) : (
          <img src={FondoNegro} alt="" className="imagenFondo" />
        )}
        <div className="centeredContainer">
          <div className={focoContainerClassName}>
            <img className="focoDark" src={FocoDark} alt="" />
            <img className="focoLight" src={FocoLight} alt="" />
          </div>
          <p className="text">
            EN NODE DIGITAL STUDIO, NOS DEDICAMOS A OFRECER SOLUCIONES CREATIVAS
            Y <br /> DIGITALES QUE IMPULSAN EL ÉXITO DE TU NEGOCIO. DESDE LA
            CREACIÓN DE PÁGINAS WEB <br /> DINÁMICAS Y ATRACTIVAS HASTA EL
            DESARROLLO DE ESTRATEGIAS DE MARCA IMPACTANTES Y <br /> EL DISEÑO
            GRÁFICO DE PRIMERA CALIDAD.
          </p>
          <div
            className={lightSwitchContainerClassName}
            onClick={() => setIsLightMode(!isLightMode)}
          >
            <img src={SunDoodle} alt="" className={isLightMode ? "hide" : ""} />
            <img
              src={MoonDoodle}
              alt=""
              className={isLightMode ? "" : "hide"}
            />
            <img
              className={lightSwitchONClassName}
              src={LightSwitchON}
              alt=""
            />
            <img
              className={lightSwitchOFFClassName}
              src={LightSwitchOFF}
              alt=""
            />
          </div>
          {/*<Button />*/}
        </div>
      </div>
      {/*
      <div className="pie-de-pagina">
        <a href="#Carru" className="Carru">
          <p className="indicador1">
            Scroll Para Continuar <FaAngleDown />
          </p>
        </a>
        <p className="indicador2"><Link to="/trabajos">Galería De Proyectos</Link></p>
      </div>*/}
      <section id="Carru" className="Carru">
        <Carrusel />
      </section>
      <div className="text-button">
        <p>
          COMPRENDEMOS LAS NECESIDADES DE NUESTROS CLIENTES <br />
          INDAGAMOS EN LO MÁS PROFUNDO PARA <br />
          COMUNICAR LO QUE REALMENTE QUIEREN
        </p>

        <div className="proyectos--button">
          <Button
            bgColor={isLightMode ? "white" : "#0b0b0b"}
            bgHoverColor={isLightMode ? "black" : "white"}
            containerClassname="proyectosButton"
          >
            <DelayLink to="/trabajos">VER TODOS LOS PROYECTOS</DelayLink>
          </Button>
        </div>
        <div className="servicios">
          <h1>DISEÑO WEB</h1>
          <h1>PROGRAMACIÓN</h1>
          <h1>DISEÑO UX/UI</h1>
          <h1>ESTRATEGIA DE MARCA</h1>
        </div>
        <div className="colaboradores">
          <h1>COLABORADORES</h1>
          <div className="cards" ref={cardsFirstRowRef}>
            {colaboradoresFirstRow.map((colaborador) => (
              <div className="card">
                <img src={colaborador.imagen} alt={colaborador.nombre} />
              </div>
            ))}
          </div>
          <div className="cards" ref={cardsSecondRowRef}>
            {colaboradoresSecondRow.map((colaborador) => (
              <div className="card">
                <img src={colaborador.imagen} alt={colaborador.nombre} />
              </div>
            ))}
          </div>
          <div className="cards-mobile">
            {colaboradoresFirstRow.map((colaborador) => (
              <div className="card">
                <img src={colaborador.imagen} alt={colaborador.nombre} />
              </div>
            ))}
            {colaboradoresSecondRow.map((colaborador) => (
              <div className="card">
                <img src={colaborador.imagen} alt={colaborador.nombre} />
              </div>
            ))}
          </div>
        </div>
        <div className="conoce">
          <p>CONOCE EL TEAM NODE</p>
          <DelayLink to="/sobrenosotros">
            <h1 className="link">NUESTRO EQUIPO</h1>
          </DelayLink>
          <p>PRESUPUESTOS SIN CARGO </p>
          <DelayLink to="/contacto">
            <h1 className="link">CONTACTANOS</h1>
          </DelayLink>
        </div>
      </div>
      <div className="contactos">
        <Link
          target="_blank"
          to="mailto:nodedigitalstudio@gmail.com?subject=Quiero%20trabajar%20con%20Node%20Digital%20Studio"
        >
          <div className="ubi">
            {isLightMode ? <DarkGmail /> : <Gmail />}
            <h2>nodedigitalstudio@gmail.com</h2>
            <p>Mail</p>
          </div>
        </Link>
        <a>
          <div className="ubi">
            {isLightMode ? <DarkDireccion /> : <Direccion />}
            <h2>Buenos Aires, Argentina</h2>
            <p>Dirección</p>
          </div>
        </a>
        <a>
          <div className="ubi">
            {isLightMode ? <DarkTelefonos /> : <Telefonos />}
            <div className="telefonos">
              <Link target="_blank" to="https://wa.me/message/ED3FLT5HXAFEM1">
                <h2 className="mati">3487-729492</h2>
              </Link>
              <Link target="_blank" to="https://wa.me/qr/HIR4UEWDQXM5F1">
                <h2 className="tomy">11-51447842</h2>
              </Link>
            </div>
            <p>Telefonos</p>
          </div>
        </a>
      </div>
      <div className="title-contenedor">
        <h1 className="title">
          {" "}
          {text.split("").map((letter, index) => (
            <span
              key={index}
              className={
                hoveredLetters.includes(index) ? "letter hovered" : "letter"
              }
              onMouseEnter={!isMobile ? () => handleMouseEnter(index) : null}
              onMouseLeave={!isMobile ? () => handleMouseLeave(index) : null}
            >
              {letter}
            </span>
          ))}
        </h1>
      </div>
    </div>
  );
}
