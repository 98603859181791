import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import { Nav } from "./componentes/navbar/nav";
import { Footer } from "../src/componentes/footer/footer";
import SobreNosotros from "./pages/sobrenosotros/sobrenosotros";
import Contacto from "../src/pages/contacto/contacto";
import Planes from "./pages/planes/planes";
import Servicios from "./pages/servicios/servicios";
import Home from "./pages/home/home";
import { Routes, Route } from "react-router-dom";
import cx from "classnames";
import Fulltex from "./pages/fulltex/fulltex";
import Trabajos from "./pages/trabajos/trabajos";
import Baires from "./pages/baires/baires";
import IaProyectos from "./pages/iaproyectos/iaproyectos";
import Boscova from "./pages/boscova/boscova";
import Condicional from "./utils/Condicional";
import Dachbone from "./pages/dachbone/dachbone";
import Landing from "./pages/landing/landing";
import ReactLenis from "@studio-freight/react-lenis";
import Mantenimiento from "./pages/landing mantenimiento/mantenimiento";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import TransitionComponent from "./componentes/SwitchTransitions/SwitchTransitions";
import { ReactComponent as TransitionSvg } from "./svg/loader.svg";

gsap.registerPlugin(useGSAP);

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLightMode, setIsLightMode] = useState(false);
  const lenisRef = useRef(null);

  useEffect(() => {
    function update(time) {
      lenisRef.current?.lenis?.raf(time * 1000);
    }

    gsap.ticker.add(update);

    return () => {
      gsap.ticker.remove(update);
    };
  });

  useGSAP(() => {
    gsap.set(".fillTransition", { xPercent: -100 });
  });

  const mainClassName = cx("main", {
    light: isLightMode,
  });

  return (
    <ReactLenis
      root
      ref={lenisRef}
      options={{
        gestureOrientataion: "both",
        smoothWheel: true,
        smoothTouch: true,
        duration: 1,
        easing: (x) => Math.sin((x * Math.PI) / 2),
      }}
    >
      <div className={mainClassName}>
        <TransitionSvg className="transitionSvgContainer" />
        <Nav
          setIsMenuOpen={setIsMenuOpen}
          isMenuOpen={isMenuOpen}
          isLightMode={isLightMode}
        />
        <Condicional test={!isMenuOpen}>
          <Routes>
            <Route
              path="/"
              element={
                <TransitionComponent>
                  <Home
                    isLightMode={isLightMode}
                    setIsLightMode={setIsLightMode}
                  />
                </TransitionComponent>
              }
            />
            <Route
              path="/planes"
              element={
                <TransitionComponent>
                  <Planes />
                </TransitionComponent>
              }
            />
            <Route
              path="/sobrenosotros"
              element={
                <TransitionComponent>
                  <SobreNosotros />
                </TransitionComponent>
              }
            />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/fulltex" element={<Fulltex />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/trabajos" element={<Trabajos />} />
            <Route path="/baires" element={<Baires />} />
            <Route path="/ia-proyectos" element={<IaProyectos />} />
            <Route path="/boscova" element={<Boscova />} />
            <Route path="/dachbone" element={<Dachbone />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/mantenimiento" element={<Mantenimiento />} />
          </Routes>
          <Footer isLightMode={isLightMode} />
        </Condicional>
      </div>
    </ReactLenis>
  );
}

export default App;
