import React, { useState, useRef, useEffect } from "react";
import "./faqitem.css";
import flechita from "../../img/landing/Vector 19.svg";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const answerRef = useRef(null);
  const abajoRef = useRef(null);
  const arribaRef = useRef(null);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      answerRef.current.style.maxHeight = `${answerRef.current.scrollHeight}px`;
      abajoRef.current.style.rotate = "180deg";
    } else {
      answerRef.current.style.maxHeight = "0";
      abajoRef.current.style.rotate = "0deg";
    }
  }, [isOpen]);

  return (
    <div className="faq-item">
      <div
        className={`faq-question ${isOpen ? "active" : ""}`}
        onClick={toggleAnswer}
      >
        {question}
        <img className="abajo" ref={abajoRef} src={flechita} alt="flechita" />

      </div>
      <div ref={answerRef} className={`faq-answer ${isOpen ? "open" : ""}`}>
        {answer}
      </div>
    </div>
  );
};

export default FAQItem;



