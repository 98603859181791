import React from "react";
import "./iaproyectos.css";
import Rayita from "../../svg/rayitas-chotas.svg";
import Rayitanaranja from "../../svg/rayitas-chotas-naranjas.svg";
import { FaAngleDown } from "react-icons/fa6";
import China from "../../img/china-ia.png";
import Chica from "../../img/chica-ia.png";
import ManoImg from "../../img/iaProyectosMano.png";
import Robotmujer from "../../img/mujer-robot-ia.png";
import Viejo from "../../img/viejo-pistola-ia.png";
import Manos from "../../img/manos-ia.png";
import { AppearTransitions } from "../../componentes/AppearTransitions/AppearTransitions";
import Next from "../../componentes/siguiente/next"

export default function iaproyectos() {
  return (
    <div className="iaproyectos-main">
      <div className="iaproyectos-contenedor">
        <div className="iaproyectos-titulo">
          <h5>IA PROYECTOS</h5>
          <div className="iaproyectos-rayita">
            <img src={Rayita} alt="" />
          </div>
        </div>
        <div className="iaproyectos-text">
          <p>
            En Node, la inteligencia artificial es una herramienta poderosa que
            utilizamos para impulsar nuestra creatividad. Nos permite visualizar
            y plasmar rápidamente nuestras ideas en bosquejos o primeras
            impresiones, capturando el momento del brainstorming y
            proporcionando una dirección clara para nuestro trabajo.{" "}
            <span style={{ color: "#EB5500" }}>
              Creemos firmemente en la importancia de este proceso, ya que
              nuestras mentes están constantemente generando y sintetizando
              ideas.
            </span>{" "}
            Consideramos que estas nuevas herramientas son una forma de
            potenciar nuestra capacidad creativa, siempre buscando avivar nuevas
            ideas y perspectivas innovadoras.
          </p>
        </div>
      </div>
      <div
        className="iaproyectos-imagen-contenedor"
        style={{ backgroundImage: `url(${Chica})` }}
      >
        <div className="iaproyectos-background-gradient" />
        <div className="hide-on-mobile">
          <div className="iaproyectos-imagen-titulo">
            <h1>
              INTELIGENCIA
              <br />
              ARTIFICIAL
            </h1>
          </div>
          <div className="iaproyectos-imagen-text">
            <p>
              Utilizamos bancos de imágenes premium, como también las mejores
              herramientas con Inteligencia artificial
            </p>
            <p>
              En esta sección mostramos un poco de la generación de imágenes con
              IA
            </p>
          </div>
        </div>
        <div className="hide-on-desktop">
          <div className="iaproyectos-contenedor">
            <div className="iaproyectos-titulo">
              <h5>IA PROYECTOS</h5>
            </div>
            <div className="iaproyectos-text">
              <p>
                En Node, la inteligencia artificial es una herramienta poderosa
                que utilizamos para impulsar nuestra creatividad. Nos permite
                visualizar y plasmar rápidamente nuestras ideas en bosquejos o
                primeras impresiones, capturando el momento del brainstorming y
                proporcionando una dirección clara para nuestro trabajo.{" "}
                <span style={{ color: "#EB5500" }}>
                  Creemos firmemente en la importancia de este proceso, ya que
                  nuestras mentes están constantemente generando y sintetizando
                  ideas.
                </span>{" "}
                Consideramos que estas nuevas herramientas son una forma de
                potenciar nuestra capacidad creativa, siempre buscando avivar
                nuevas ideas y perspectivas innovadoras.
              </p>
            </div>
            <div className="iaproyectos-rayita">
              <p>//</p>
            </div>
          </div>
        </div>
      </div>
      <div className="iaproyectos-texto">
        <p>Boscova es una cervecería de Córdoba Argentina</p>
      </div>
      <div className="iaproyectos-carpincho-contenedor">
        <AppearTransitions
          direction="izquierda"
          className="iaproyectos-carpincho"
        >
          <img src={ManoImg} alt="" />
        </AppearTransitions>
        <AppearTransitions
          direction="derecha"
          className="iaproyectos-carpincho-text"
        >
          <p>
            En nuestro constante viaje hacia la potenciación de nuestra
            creatividad y eficiencia en el diseño, nos aventuramos a explorar
            nuevas fronteras. Aprovechando la inteligencia artificial y
            herramientas de generación de imágenes, hemos transformado nuestros
            procesos para alcanzar resultados sorprendentes en un tiempo récord.
          </p>
          <p>
            Nuestra asociación con la inteligencia artificial nos ha permitido
            desbloquear un amplio espectro de ideas y conceptos en tiempo
            récord. Apoyados en algoritmos avanzados, analizamos datos y
            patrones, lo que nos brinda una visión privilegiada para identificar
            tendencias y oportunidades creativas.
          </p>
          <p>
            Además, empleamos herramientas de generación de imágenes para dar
            vida a nuestras ideas de manera rápida y eficaz. Estas herramientas
            nos permiten esbozar y representar visualmente nuestras visiones en
            cuestión de minutos, acelerando significativamente nuestro proceso
            creativo.
          </p>
          <p>
            La combinación de inteligencia artificial con herramientas de
            generación de imágenes ha resultado en una optimización palpable de
            nuestros procesos creativos y en la obtención de resultados
            excepcionales. Estamos entusiasmados por continuar explorando el
            potencial de estas tecnologías y por seguir elevando el estándar en
            el diseño digital.
          </p>
        </AppearTransitions>
      </div>
      <AppearTransitions
        direction="derecha"
        className="iaproyectos-sobre-el-proyecto-rayita"
      >
        <img src={Rayitanaranja} alt="" />
        <h1>
          ADOBE
          <br /> FIREFLY
        </h1>
      </AppearTransitions>
      <AppearTransitions
        direction="izquierda"
        className="iaproyectos-sobre-el-proyecto"
      >
        <h1>ADOBE FIREFLY</h1>
        <p>
          Adobe Firefly representa una verdadera revolución en el mundo del
          diseño digital, ofreciendo una combinación perfecta de creatividad y
          eficiencia. Con su interfaz intuitiva y sus poderosas funciones,
          Firefly capacita a los diseñadores para crear una amplia variedad de
          elementos visuales sorprendentes de manera rápida y efectiva.
        </p>
        <p>
          Esta innovadora herramienta proporciona una amplia gama de
          funcionalidades, que van desde la creación de ilustraciones y gráficos
          hasta la edición de imágenes y la composición de diseños complejos.
          Equipado con características avanzadas, como capas inteligentes,
          ajustes de imagen y filtros creativos, Firefly otorga a los
          diseñadores un control total sobre sus proyectos creativos.
        </p>
      </AppearTransitions>
      <div className="iaproyectos-galeria">
        <img src={China} alt="" className="china" />
        <img src={Viejo} alt="" className="viejo" />
        <img src={Robotmujer} alt="" className="robotmujer" />
        <img src={Manos} alt="" className="manos" />
        <div className="textos">
          <div className="centered">
            <h1>PROYECTOS</h1>
            <p>
              Este proyecto tiene como objetivo la integración de la
              inteligencia artificial (IA) en el proceso de diseño de una
              agencia creativa, con el propósito de mejorar la eficiencia y la
              calidad del trabajo creativo mediante el uso de algoritmos de IA y
              herramientas de generación de imágenes.
            </p>
            <p>
              Esta iniciativa ha revolucionado nuestra forma de trabajo,
              mejorando notablemente nuestra eficiencia y la calidad de nuestro
              trabajo creativo.
            </p>
            <p>
              Comenzamos con una extensa investigación sobre las aplicaciones de
              la IA en el diseño creativo, evaluando nuestras necesidades
              específicas. Tras una cuidadosa selección de herramientas,
              capacitamos a nuestro equipo en su uso y llevamos a cabo un
              proyecto piloto para probar su efectividad.
            </p>
            <p>
              Una vez demostrado el éxito, implementamos estas nuevas
              herramientas y procesos en todos nuestros proyectos de diseño.
              Realizamos evaluaciones continuas para identificar áreas de mejora
              y mantenernos al tanto de las últimas tendencias y tecnologías en
              el diseño creativo.
            </p>
            <p>
              Gracias a esta iniciativa, hemos logrado potenciar nuestra
              eficiencia y calidad en el proceso de diseño, ofreciendo
              resultados excepcionales a nuestros clientes y posicionándonos a
              la vanguardia de la innovación en el diseño creativo.
            </p>
          </div>
        </div>
      </div>
      <Next  siguiente="/boscova" />
    </div>
  );
}
