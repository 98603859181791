import React, { useRef, useEffect } from "react";
import "./webs.css";
import DelayLink from "../SwitchTransitions/DelayLink";
export const webs = ({ nombre, descripcion, link }) => {
  return (
    <div className="trabajos-contenedor-main">
      <DelayLink to={link}>
    
        <div className="trabajos-text">
          <h1>{nombre}</h1>
          <p>{descripcion}</p>
          
        </div>
        </DelayLink>
    </div>
  );
};
export default webs;
