import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { horizontalLoop } from "../../utils/horizontal-loop";
import "./ImagenCambiaEnHover.css";

gsap.registerPlugin(useGSAP);

export const ImagenCambiaEnHover = ({
  children,
  className,
  currentImage,
  isFirstRender,
}) => {
  const imageContainerRef = useRef(null);
  const loopRef = useRef();

  useEffect(() => {
    if (isFirstRender) return;
    moveImage(currentImage);
  }, [currentImage, isFirstRender]);

  useGSAP(() => {
    gsap.context(() => {
      const images = gsap.utils.toArray(
        `.imagen-de-cliente-contenedor .contenedor-hide .${className}`
      );
      console.log(images);
      loopRef.current = horizontalLoop(images, {
        paused: true,
      });
    }, imageContainerRef.current);
  });

  const moveImage = (i) => {
    console.log("moveImage", i);
    loopRef.current.toIndex(i, { duration: 0.8, ease: "power1.inOut" });
  };

  return (
    <div ref={imageContainerRef} className={`imagen-de-cliente-contenedor`}>
      <div className="contenedor-hide">{children}</div>
    </div>
  );
};
