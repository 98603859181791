import React from "react";
import Baires from "../../img/serviciosCard1.jpg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import "./index.css";

export const CardWIthImageAnimation = ({ titulo, texto, imagen, doodle }) => {
  gsap.registerPlugin(useGSAP);
  const imageRef = useRef(false);
  const imageContainerRef = useRef(false);

  const containerRef = useRef(false);
  const animationSpacerRef = useRef(false);
  const whiteDotRef = useRef(false);
  const timelineRef = useRef(false);

  useEffect(() => {
    const height =
      containerRef.current.clientHeight -
      containerRef.current.clientHeight * 0.35;
    console.log(height, containerRef.current.clientHeight);
    gsap.set(imageContainerRef.current, {
      height: height,
    });
    timelineRef.current = gsap
      .timeline({ paused: true })
      .to(animationSpacerRef.current, {
        height: height,
        duration: 0.5,
      })
      .to(imageRef.current, {
        opacity: 1,
        duration: 0.5,
      })
      .to(whiteDotRef.current, {
        width: 2000,
        height: 2000,
        x: 300,
        y: -300,
        scale: 1,
        duration: 0.5,
        ease: "power1.inOut",
      });
    return () => timelineRef.current.kill();
  }, []);

  /*spaceTween*/
  const hoverOn = () => {
    timelineRef.current.timeScale(1).play();
    //spaceTween.play();
  };

  const hoverOff = () => {
    timelineRef.current.timeScale(1.5).reverse();
    //spaceTween.tweenTo(0);
  };

  return (
    <div
      className="servicios-explicacion"
      onMouseEnter={!isMobile ? () => hoverOn() : null}
      onMouseLeave={!isMobile ? () => hoverOff() : null}
      ref={containerRef}
    >
      <div className="animation-spacer" ref={animationSpacerRef} />
      <div
        className="contenedor-servicios-explicacion-image"
        ref={imageContainerRef}
      >
        <div className="whiteDot" ref={whiteDotRef} />
        <div
          className="servicios-explicacion-image"
          ref={imageRef}
          style={{ backgroundImage: `url(${imagen})` }}
        />
      </div>
      {doodle()}
      <h1>{titulo}</h1>
      <p>{texto}</p>
    </div>
  );
};
