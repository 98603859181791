import React from "react";
import "./next.css";
import { Link } from "react-router-dom";
import { GrFormNext } from "react-icons/gr";
import DelayLink from "../SwitchTransitions/DelayLink";

export const next = ({ siguiente }) => {
  return (
    <div className="next-contenedor">
      <div className="next">
        <DelayLink to={siguiente}>
          <p className="coso">
            Siguiente Proyecto <GrFormNext />
          </p>
        </DelayLink>
      </div>
    </div>
  );
};
export default next;
