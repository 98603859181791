import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import gsap from "gsap";

export const DelayLink = (props) => {
  const { delay, onDelayStart, onDelayEnd, replace, to, ...rest } = props;
  let timeout = null;
  let history = useNavigate();
  let location = useLocation();

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  const handleClick = (e) => {
    if (location?.pathname === to) return;

    onDelayStart(e, to);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    gsap.set(".transitionSvgContainer", { zIndex: 10000 });
    let loader = gsap.timeline({});
    loader
      .to(".transitionSvg", {
        duration: 0.5,
        attr: { d: "M0 502S175 272 500 272s500 230 500 230V0H0Z" },
        ease: "power2.in",
      })
      .to(".transitionSvg", {
        duration: 0.4,
        attr: { d: "M0,1005S175,995,500,995s500,5,500,5V0H0Z" },
        ease: "power2.out",
      });
    timeout = setTimeout(() => {
      loader
        .to(".transitionSvg", {
          duration: 0.5,
          attr: { d: "M0 502S175 272 500 272s500 230 500 230V0H0Z" },
          ease: "power2.in",
        })
        .to(".transitionSvg", {
          duration: 0.5,
          attr: { d: "M0 2S175 1 500 1s500 1 500 1V0H0Z" },
          ease: "power2.out",
        })
        .to(".transitionSvgContainer", { zIndex: -1 });
        window.scrollTo(0, 0);
      history(to, { replace });
      onDelayEnd(e, to);
    }, delay);
  };

  return <Link {...rest} to={to} onClick={handleClick} />;
};

DelayLink.propTypes = {
  delay: PropTypes.number,
  onDelayStart: PropTypes.func,
  onDelayEnd: PropTypes.func,
  replace: PropTypes.bool,
  to: PropTypes.string,
};

DelayLink.defaultProps = {
  replace: false,
  delay: 1200,
  onDelayStart: () => {},
  onDelayEnd: () => {},
};

export default DelayLink;
