import React from "react";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isMobile } from "react-device-detect";

gsap.registerPlugin(useGSAP, ScrollTrigger);

export const AppearTransitions = ({
  children,
  direction,
  animationStart = 300,
  className,
}) => {
  const containerRef = useRef();

  useGSAP(() => {
    if (isMobile) {
      return;
    }
  
    // Ajustes comunes para todas las direcciones
    const animationConfig = {
      opacity: 1,
      duration: 1.5, // Duración más larga para suavidad
      ease: "power3.out", // Easing suave para que empiece rápido y termine lento
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top bottom-=${animationStart}`,
      },
    };
  
    if (direction === "izquierda") {
      gsap.set(containerRef.current, {
        opacity: 0,
        xPercent: -10,
      });
      gsap.to(containerRef.current, {
        xPercent: 0,
        ...animationConfig,
      });
    }
  
    if (direction === "derecha") {
      gsap.set(containerRef.current, {
        opacity: 0,
        xPercent: 10,
      });
      gsap.to(containerRef.current, {
        xPercent: 0,
        ...animationConfig,
      });
    }
  
    if (direction === "arriba") {
      gsap.set(containerRef.current, {
        opacity: 0,
        yPercent: -30,
      });
      gsap.to(containerRef.current, {
        yPercent: 0,
        ...animationConfig,
      });
    }
  
    if (direction === "abajo") {
      gsap.set(containerRef.current, {
        opacity: 0,
        yPercent: 100,
      });
      gsap.to(containerRef.current, {
        yPercent: 0,
        ...animationConfig,
      });
    }
  });
  

  return (
    <div ref={containerRef} className={className}>
      {children}
    </div>
  );
};
