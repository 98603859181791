import React, { useState, useRef } from "react";
import "./nav.css";
import Nnegra from "../../svg/N-negra.svg";
import Nblanca from "../../svg/N-blanca.svg";
import { Cross as Hamburger } from "hamburger-react";
import classNames from "classnames";
import { useLocation, Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import DelayLink from "../SwitchTransitions/DelayLink";

export const Nav = ({ setIsMenuOpen, isMenuOpen, isLightMode }) => {
  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const container = useRef(false);
  const logoAnimationRef = useRef(false);

  const { contextSafe } = useGSAP({ scope: container });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useGSAP(() => {
    if (location.pathname !== "/" && location.pathname !== "/*") {
      gsap.to(container.current, {
        ease: "none",
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0,0,0,.3)",
        scrollTrigger: {
          start: "top+=60 top",
          end: "end",
          scrub: 1,
        },
      });
    }
  }, []);

  const location = useLocation();
  const whiteModeNav =
    isLightMode &&
    !isMenuOpen &&
    (location.pathname === "/" || location.pathname === "/*");
  const paginasNegras = [
    "/trabajos",
    "/fulltex",
    "/baires",
    "/ia-proyectos",
    "/boscova",
    "/dachbone",
  ];
  const paginasNegrasEnMobile = ["/trabajos", "/fulltex"];
  console.log("pathname", location.pathname);
  const navClassnames = classNames("nav", {
    "nav-negro":
      (paginasNegras.includes(location.pathname) && !isMenuOpen) ||
      (isLightMode && !isMenuOpen),
    "nav-negro-mobile":
      paginasNegrasEnMobile.includes(location.pathname) ||
      (isLightMode && !isMenuOpen),
    hidden:
      location.pathname === "/landing" ||
      location.pathname === "/mantenimiento",
  });

  /* GSAP TWEENS */

  useGSAP(() => {
    logoAnimationRef.current = gsap
      .timeline({ paused: true })
      .to(".puntito", {
        x: 60,
        duration: 1,
      })
      .to(
        ".puntito-naranja",
        {
          x: 0,
          duration: 1,
        },
        "<"
      );
  });

  const animateDotsOnHover = contextSafe(() => {
    logoAnimationRef.current.play();
  });

  const animateDotsOnLeave = contextSafe(() => {
    logoAnimationRef.current.timeScale(2).reverse();
  });

  /* GSAP TWEENS */

  return (
    <>
      <div id="navBar" className={navClassnames} ref={container}>
        <div
          className="logo-contenedor"
          onMouseEnter={() => animateDotsOnHover()}
          onMouseLeave={() => animateDotsOnLeave()}
        >
          <div className="puntito" />
          <div className="puntito-naranja" />
          <DelayLink to="/">
            <div className="hide-on-mobile">
              {" "}
              {paginasNegras.includes(location.pathname) && !isMenuOpen ? (
                <img className="logo" src={Nnegra} alt="" />
              ) : isLightMode && !isMenuOpen ? (
                <img className="logo" src={Nnegra} alt="" />
              ) : (
                <img className="logo" src={Nblanca} alt="" />
              )}
            </div>
            <div className="hide-on-desktop">
              {" "}
              {paginasNegrasEnMobile.includes(location.pathname) ? (
                <img className="logo" src={Nnegra} alt="" />
              ) : isLightMode && !isMenuOpen ? (
                <img className="logo" src={Nnegra} alt="" />
              ) : (
                <img className="logo" src={Nblanca} alt="" />
              )}
            </div>
          </DelayLink>
        </div>
        <div className="menu-hambur">
          <p>Menu</p>{" "}
          <a onClick={toggleMenu} href="#">
            {" "}
            <div className="hide-on-mobile">
              <Hamburger
                size={20}
                className="hambur"
                toggled={isMenuOpen}
                color={
                  (paginasNegras.includes(location.pathname) && !isMenuOpen) ||
                  (isLightMode && !isMenuOpen)
                    ? "rgb(0, 0, 0)"
                    : "rgb(255, 255, 255)"
                }
              />
            </div>
            <div className="hide-on-desktop">
              <Hamburger
                size={20}
                toggled={isMenuOpen}
                className="hambur"
                color={
                  paginasNegrasEnMobile.includes(location.pathname) ||
                  (isLightMode && !isMenuOpen)
                    ? "rgb(0, 0, 0)"
                    : "rgb(255, 255, 255)"
                }
              />
            </div>
          </a>
        </div>
      </div>
      {isMenuOpen && (
        <div className="menu-items">
          <ul className="contenedor-ul">
            <DelayLink to="/sobrenosotros" onDelayEnd={toggleMenu}>
              <div className="nuestro">
                <li className="text-top">SOBRE NOSOTROS</li>
                <li className="text-bottom">SOBRE NOSOTROS</li>
              </div>
            </DelayLink>
            <DelayLink to="/servicios" onDelayEnd={toggleMenu}>
              <div className="nuestro">
                <li className="text-top">SERVICIOS</li>
                <li className="text-bottom">SERVICIOS</li>
              </div>
            </DelayLink>
            <DelayLink to="/planes" onDelayEnd={toggleMenu}>
              <div className="nuestro">
                <li className="text-top">PLANES</li>
                <li className="text-bottom">PLANES</li>
              </div>
            </DelayLink>
            <DelayLink to="/contacto" onDelayEnd={toggleMenu}>
              <div className="nuestro">
                <li className="text-top">CONTACTOS</li>
                <li className="text-bottom">CONTACTOS</li>
              </div>
            </DelayLink>
            <DelayLink to="/trabajos" onDelayEnd={toggleMenu}>
              <div className="nuestro">
                <li className="text-top">TRABAJOS</li>
                <li className="text-bottom">TRABAJOS</li>
              </div>
            </DelayLink>
          </ul>
        </div>
      )}
    </>
  );
};
export default Nav;
