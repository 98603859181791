import React from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import gsap from "gsap";

const TransitionComponent = ({ children }) => {
  const location = useLocation();
  console.log("HI", location);
  return (
    <TransitionGroup>
      <Transition
        key={location.pathname}
        timeout={1500}
        onEnter={(node) => {
          console.log("entered");
          gsap.set(".fillTransition", { autoAlpha: 0, xPercent: -100 });
          gsap
            .timeline({ paused: true })
            .to(".fillTransition", { autoAlpha: 1, xPercent: 0, duration: 1 })
            .to(".fillTransition", {
              autoAlpha: 1,
              xPercent: 100,
              duration: 1,
              delay: 0.5,
            })

            .play();
        }}
      >
        {children}
      </Transition>
    </TransitionGroup>
  );
};

export default TransitionComponent;
