import React from "react";
import "./trabajos.css";
import Webs from "../../componentes/webs/webs";
import Rayita from "../../svg/rayitas-chotas.svg";
import focoDoodle from "../../img/focoDoodle.svg";

export default function trabajos() {
  const trabajosDatos = [
    {
      nombre: "FULLTEX",
      descripcion: "Revestimiento Plastico Texturado",
      link: "/fulltex",
    },
    {
      nombre: "BAIRES",
      descripcion: "House Of Music",
      link: "/baires",
    },
    {
      nombre: "IA PROYECTOS",
      descripcion: "Proyectos Varios de Ia",
      link: "/ia-proyectos",
    },
    {
      nombre: "BOSCOVA",
      descripcion: "Cervecería Artesanal",
      link: "/boscova",
    },
    {
      nombre: "DACHBONE",
      descripcion: "Accesorios para Animales",
      link: "/dachbone",
    },
  ];
  return (
    <div className="trabajosContainer">
      {trabajosDatos.map((trabajo) => (
        <Webs
          nombre={trabajo.nombre}
          link={trabajo.link}
          descripcion={trabajo.descripcion}
        />
      ))}
      <div className="contenedor-canchero">
        <img src={Rayita} alt="" />
        <div className="contenedor-texto">
          <p>
            Entendemos de Ideas!
            <br />Y Diseño!
          </p>
        </div>
      </div>
    </div>
  );
}
